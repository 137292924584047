import { WebCenterType } from 'app/center-v2/shared/models';
import { WebEnvironmentUser } from 'app/center-v2/shared/models/web-center-types/web-environment-user.model';
import { WebBaseUser } from './web-base-user.model';

export class StructuralAdminUserResponse {

  web2BaseUser: WebBaseUser;
  web2EnvironmentUser: WebEnvironmentUser;
  web2CenterTypes: WebCenterType[];

  constructor(item?: Partial<StructuralAdminUserResponse>) {
    Object.assign(this, item);

    this.web2BaseUser = this.web2BaseUser ? new WebBaseUser(this.web2BaseUser) : undefined;
    this.web2EnvironmentUser = this.web2EnvironmentUser ? new WebEnvironmentUser(this.web2EnvironmentUser) : undefined;

    this.web2CenterTypes = (this.web2CenterTypes || []).map(x => new WebCenterType(x));
  }
}