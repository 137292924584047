import { Injectable } from '@angular/core';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class SolutionDataService {

  static SolutionDataEngineV1GuidId = 'c109e71a-d621-44c6-8ef5-cc1f36562d1e';

  private urlSuffixPlaceholder = 'centerv2/solutiondata/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  runMap(
    solutionDataMapCallGuidId: string,
    resourceGuidId: string,
    fromSource: boolean,
    sourceRootTypeGuidId?: string,
    sourceGuidId?: string,
    sourceRelationTypeGuidId?: string,
  ): Observable<void> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'map/run'),
      {
        solutionDataMapCallGuidId: solutionDataMapCallGuidId,
        resourceGuidId: resourceGuidId,
        fromSource: fromSource,
        sourceRootTypeGuidId: sourceRootTypeGuidId,
        sourceGuidId: sourceGuidId,
        sourceTypeRelationGuidId: sourceRelationTypeGuidId
      }
    ).pipe(
      map((response: any) => {
        return response?.solutionDataMapRunWeb2Object ? response?.solutionDataMapRunWeb2Object : null;
      })
    );
  }

  getModel(
    solutionDataMapCallGuidId: string,
    contentType?: string,
  ): Observable<Blob> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'map/getmodel'),
      {
        solutionDataMapCallGuidId: solutionDataMapCallGuidId,
        contentType: contentType,
      },
      {
        observe: 'body',
        responseType: 'blob',
      }
    );
  }

}
