import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';



export class WebSite extends WebObject {

  static typeGuidId = '5706dd4e-87f9-4e04-8a23-353d10554302';

  members: {
    name: string;
    shortName: string;
    coreAdminSite?: boolean;
  };

  relations: {
    logicAddress?: WebRelationPointer;
    logicGroups?: WebRelationPointer[];
    adminUser?: WebRelationPointer;
    environmentSite?: WebRelationPointer;
    environmentSites?: WebRelationPointer[];
  };

  constructor(item?: Partial<WebSite>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebSite.typeGuidId;
  }

}