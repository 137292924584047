import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const appRoutes: Routes = [
  {
    path: 'auth', redirectTo: 'v2/auth',
  },
  {
    path: 'dt/:taskExternalShareBaseGuidId', redirectTo: 'v2/center/deviation-task/:taskExternalShareBaseGuidId',
  },
  {
    path: 'ep', redirectTo: 'event-system/portal',
  },
  {
    path: 'oauth2/redirect', redirectTo: 'v2/auth/oauth2',
  },

  {
    path: 'accesscode',
    loadChildren: () => import('app/access-code-redirect/access-code-redirect.module').then(m => m.AccessCodeRedirectModule)
  },
  {
    path: 'center',
    loadChildren: () => import('app/center/center.module').then(m => m.CenterModule)
  },
  {
    path: 'demos',
    loadChildren: () => import('app/demos/demos.module').then(m => m.DemosModule)
  },
  {
    path: 'event-system',
    loadChildren: () => import('app/event-system/event-system.module').then(m => m.EventSystemModule)
  },
  {
    path: 'horeca',
    loadChildren: () => import('app/horeca/horeca.module').then(m => m.HoReCaModule)
  },
  {
    path: 'ideas',
    loadChildren: () => import('app/idea-system/idea-system.module').then(m => m.IdeasModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('app/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('app/partners/partners.module').then(m => m.PartnersModule)
  },
  {
    path: 'p', // 'portal'
    loadChildren: () => import('app/portal-system/portal-system.module').then(m => m.PortalSystemPageModule)
  },
  {
    path: 'studio',
    loadChildren: () => import('app/studio/studio.module').then(m => m.StudioModule)
  },
  {
    path: 'tv',
    loadChildren: () => import('app/tv/tv.module').then(m => m.TvModule)
  },
  {
    path: 'v2/auth',
    loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'v2/center',
    loadChildren: () => import('app/center-v2/center-v2.module').then(m => m.CenterV2Module)
  },

  // custom login modules
  {
    path: 'volymkoll',
    loadChildren: () => import('app/volymkoll/volymkoll.module').then(m => m.VolymkollModule)
  },
  // ---

  {
    path: '**',
    redirectTo: 'landing'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
