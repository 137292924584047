import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { ButtonComponentModule } from 'app/shared/components/button/button.component.module';
import { ConfirmDialogModule } from 'app/shared/dialogs/confirm/confirm.dialog.module';
import { PromptDialogModule } from 'app/shared/dialogs/prompt/prompt.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TreeModule } from 'primeng/tree';
import { AccessSiteTreeComponent } from './access-site-tree.component';


@NgModule({
  declarations: [
    AccessSiteTreeComponent,
  ],
  exports: [
    AccessSiteTreeComponent,
  ],
  imports: [
    AppSharedV2Module,
    ButtonComponentModule,
    ConfirmDialogModule,
    ContextMenuModule,
    NgxLoadingModule,
    PromptDialogModule,
    TreeModule,
  ],
  providers: [],
})
export class AccessSiteTreeComponentModule { }

