import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxLoadingModule } from 'ngx-loading';
import { PrimeNgModule } from '../primeng/primeng.module';
import { ButtonComponentModule } from './components/button/button.component.module';
import { OwnerSiteModule } from './components/owner-site/owner-site.module';
import { TopBarModule } from './components/top-bar/top-bar.module';
import { TreeComponentModule } from './components/tree/tree.component.module';
import { NewSolutionFeatureDialog, SolutionFeaturesDialog } from './dialogs';
import { ConfirmDialogModule } from './dialogs/confirm/confirm.dialog.module';
import { InfoDialogModule } from './dialogs/info/info.dialog.module';
import { PromptDialogModule } from './dialogs/prompt/prompt.module';
import { SelectSiteDialogModule } from './dialogs/select-site/select-site.dialog.module';
import { PipesModule } from './pipes/pipes.module';



@NgModule({
  imports: [
    ButtonComponentModule,
    CommonModule,
    FormsModule,

    ClickOutsideModule,
    ConfirmDialogModule,
    InfoDialogModule,
    NgxLoadingModule,
    OwnerSiteModule,
    PipesModule,
    PrimeNgModule.forRoot(),
    PromptDialogModule,
    SelectSiteDialogModule,
    TopBarModule,
    TranslateModule,
    TreeComponentModule,

  ],
  declarations: [

    SolutionFeaturesDialog,
    NewSolutionFeatureDialog,
  ],
  exports: [
    ButtonComponentModule,
    CommonModule,
    FormsModule,

    ClickOutsideModule,
    ConfirmDialogModule,
    InfoDialogModule,
    NgxLoadingModule,
    OwnerSiteModule,
    PipesModule,
    PrimeNgModule,
    SelectSiteDialogModule,
    TopBarModule,
    TranslateModule,
    TreeComponentModule,

    PromptDialogModule,

    SolutionFeaturesDialog,
    NewSolutionFeatureDialog,
  ],
})
export class AppSharedModule { }
