import { AgRendererComponent } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JsonUtils } from 'app/shared/utils';
import { BaseGridCellRenderer } from '../base/base-grid-cellrenderer.component';


@Component({
  selector: 'lc-grid-cellrenderer-calendar-drag-handle',
  templateUrl: 'grid-cellrenderer-calendar-drag-handle.component.html',
  styleUrls: ['grid-cellrenderer-calendar-drag-handle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellRendererCalendarDragHandle extends BaseGridCellRenderer implements AgRendererComponent {

  readonly bodyEl = document.querySelector('body');

  dragLabel: string;

  constructor(
    cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    super(cdr);
  }

  agInit(params): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    const selectedRows = this.params.api.getSelectedRows()?.length > 1 ? this.params.api.getSelectedRows() : [this.params.data];
    this.dragLabel = selectedRows.length === 1 ?
    (JsonUtils.deepFind(this.params.data, this.params?.colDef?.cellRendererParams?.field) || this.params.data?.members?.name) :
    (selectedRows.length + ' ' + this.translateService.instant('Tasks'));
    this.cdr.markForCheck();

    return false;
  }

  getDropData(): any {
    const selectedRows = this.params.api.getSelectedRows()?.length > 1? this.params.api.getSelectedRows() : [this.params.data];
    return {
      event: {
        $guidIds: selectedRows.map((r: any) => r.guidId),
      }
    };
  }


}
