import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.googleApiKey) {
  const gmapsApiScriptEl = document.createElement('script') as HTMLScriptElement;
  gmapsApiScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}`;
  gmapsApiScriptEl.async = true;
  gmapsApiScriptEl.defer = true;
  document.head.appendChild(gmapsApiScriptEl);
}

if (environment.production) {
  // add Google Analytics script to <head>
  const gtagSetupScript = document.createElement('script');
  gtagSetupScript.innerHTML = `(function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function() {
        (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
ga('create', '${(window as any).gaKey}', 'auto');`;

  document.head.appendChild(gtagSetupScript);
}

// handle all <a> click events to custom handle workspace widget navigation
document.addEventListener('click', (ev: any) => {
  const origin = ev.target.closest('a');

  if (origin?.href?.indexOf('/widget-link') >= 0) {
    ev.preventDefault();

    if ((window as any).widgetShortcut) {
      const params = origin.href.split('?')[1].split('&').filter(x => x)
      .reduce((previousValue: any, currentValue: any) => {
        const keyValuePair = currentValue.split('=');
        previousValue[keyValuePair[0]] = keyValuePair[1];
        return previousValue;
      }, {});

      (window as any).widgetShortcut(params);
    }
    return true;
  }

  return false;
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
.catch(err => console.warn(err));
