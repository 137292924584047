import { Injectable } from '@angular/core';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebLoadMap, WebResponse } from '../../../shared/models';
import { StructuralAdminSiteResponse } from '../models/structural-admin-site-response.model';
import { StructuralAdminUserResponse } from '../models/structural-admin-user-response.model';
import { WebDesignTree } from '../../../shared/models/web-base/web-design-tree.model';
import { WebBaseSite } from '../models/web-base-site.model';
import { WebBaseUser } from '../models/web-base-user.model';


export enum UserUsage {
  NoUsers = 0x000,
  None = 0x001,
  Device = 0x010,
  Web = 0x100,
}

@Injectable({
  providedIn: 'root'
})
export class StructuralAdminService {

  private urlSuffixPlaceholder = 'centerv2/solutionadmin/structuraladmin/{what}';

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  addSite(parentGuidId: string, name: string): Observable<StructuralAdminSiteResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'site/add'),
      {
        name: name,
        siteGuidId: parentGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new StructuralAdminSiteResponse(response) : null;
      })
    );
  }

  addSiteSubType(siteGuidId: string, typeGuidId: string, includeCenterTypes?: boolean): Observable<StructuralAdminSiteResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'site/newsubtype'),
      {
        guidId: siteGuidId,
        includeCenterTypes: includeCenterTypes,
        typeGuidId: typeGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new StructuralAdminSiteResponse(response) : null;
      })
    );
  }

  addUser(parentGuidId: string, name: string): Observable<StructuralAdminUserResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/add'),
      {
        name: name,
        siteGuidId: parentGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new StructuralAdminUserResponse(response) : null;
      })
    );
  }

  addUserUsageDevice(userGuidId: string): Observable<StructuralAdminUserResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/usage/device/add'),
      {
        userGuidId: userGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new StructuralAdminUserResponse(response) : null;
      })
    );
  }

  addUserUsageWeb(userGuidId: string): Observable<StructuralAdminUserResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/usage/web/add'),
      {
        userGuidId: userGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new StructuralAdminUserResponse(response) : null;
      })
    );
  }

  addUserWorkspace(userGuidId: string, parentWorkspaceGuidId: string, name?: string): Observable<StructuralAdminUserResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/usage/web/workspace/add'),
      {
        name: name,
        parentWorkspaceGuidId: parentWorkspaceGuidId,
        userGuidId: userGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new StructuralAdminUserResponse(response) : null;
      })
    );
  }

  removeUserWorkspace(userGuidId: string, removeWorkspaceGuidId: string): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/usage/web/workspace/remove'),
      {
        removeWorkspaceGuidId: removeWorkspaceGuidId,
        userGuidId: userGuidId,
      }
    );
  }

  getSite(siteGuidId: string, webLoadMap?: WebLoadMap, includeCenterTypes?: boolean, nonGenericTypeGuidIds?: string[]): Observable<StructuralAdminSiteResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'site/get'),
      {
        includeCenterTypes: includeCenterTypes,
        nonGenericTypeGuidIds: nonGenericTypeGuidIds,
        siteGuidId: siteGuidId,
        web2LoadMap: webLoadMap || new WebLoadMap(),
      }
    ).pipe(
      map((response: any) => {
        return response ? new StructuralAdminSiteResponse(response) : null;
      })
    );
  }

  getUser(userGuidId: string, webLoadMap?: WebLoadMap, includeCenterTypes?: boolean): Observable<StructuralAdminUserResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/get'),
      {
        includeCenterTypes: includeCenterTypes,
        userGuidId: userGuidId,
        web2LoadMap: webLoadMap || new WebLoadMap(),
      }
    ).pipe(
      map((response: any) => {
        return response ? new StructuralAdminUserResponse(response) : null;
      })
    );
  }

  listUserWorkspaces(userGuidId: string): Observable<WebResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/usage/web/workspace/list'),
      {
        userGuidId: userGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : null;
      })
    );
  }

  list(filterSiteGuidId: string, filterLevels: number, userUsageFilter: UserUsage, includeEnvironmentSites?: boolean, includeEnvironmentUsers?: boolean): Observable<WebDesignTree> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
      {
        filterSiteGuidId: filterSiteGuidId,
        filterLevels: filterLevels || 0,
        includeEnvironmentSites: includeEnvironmentSites,
        includeEnvironmentUsers: includeEnvironmentUsers,
        userUsageFilter: userUsageFilter,
      }
    ).pipe(
      map((response: any) => {
        return response?.web2DesignTreeObject ? new WebDesignTree(response.web2DesignTreeObject) : null;
      })
    );
  }

  removeSite(webBaseSite: WebBaseSite): Observable<void> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'site/remove'),
      {
        web2BaseSite: webBaseSite,
      }
    );
  }

  removeUser(webBaseUser: WebBaseUser): Observable<void> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/remove'),
      {
        web2BaseUser: webBaseUser,
      }
    );
  }

  updateSite(webBaseSite: WebBaseSite): Observable<StructuralAdminSiteResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'site/update'),
      {
        web2BaseSite: webBaseSite,
      }
    ).pipe(
      map((response: any) => {
        return response ? new StructuralAdminSiteResponse(response) : null;
      })
    );
  }

  updateUser(webBaseUser: WebBaseUser): Observable<StructuralAdminUserResponse> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/update'),
      {
        web2BaseUser: webBaseUser,
      }
    ).pipe(
      map((response: any) => {
        return response ? new StructuralAdminUserResponse(response) : null;
      })
    );
  }

  generateDeviceUserPassword(userGuidId: string): Observable<string> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/usage/device/generatedevicepassword'),
      {
        userGuidId: userGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response.newPassword ? response.newPassword : undefined;
      })
    );
  }

  generateWebUserPassword(userGuidId: string, emailUser: boolean): Observable<string> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'user/web/generatenewpassword'),
      {
        userGuidId: userGuidId,
        emailUser: emailUser,
        baseUri: window.location.origin,
      }
    ).pipe(
      map((response: any) => {
        return response.tempPassword ? response.tempPassword : undefined;
      })
    );
  }




  /** @deprecated */
  getUserDesignTree(siteGuidId: string): Observable<any> {
    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'users/userdesigntree'),
      {
        guidId: siteGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

}
