import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { GridComponent } from '../grid.component';
import { GridUtils } from 'app/shared/utils';


@Component({
  selector: 'lc-grid-cellrenderer-detail-grids',
  templateUrl: 'grid-cellrenderer-detail-grids.component.html',
  styleUrls: ['grid-cellrenderer-detail-grids.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellRendererDetailGrids implements ICellRendererAngularComp, OnDestroy {

  @ViewChildren(GridComponent) grids: QueryList<GridComponent>;

  params: any;

  detailGrids: any;

  masterGridApi: any;
  masterRowIndex: number;
  gridReady = this.onGridReady.bind(this);

  constructor(
    private cdr: ChangeDetectorRef,
  ) {

  }

  agInit(params): void {
    this.params = params;

    this.detailGrids = [];
    this.masterGridApi = this.params?.api;
    this.masterRowIndex = this.params?.rowIndex;

    for (const detailGridOption of this.params?.detailGridsOptions || []) {
      const detailGrid = Object.assign({}, detailGridOption);

      if (detailGrid.getGridRows && typeof detailGrid.getGridRows === 'function') {
        this.refreshGrid(detailGrid);
      } else {
        detailGrid.gridRows = detailGrid.gridRows || [];
        this.cdr.markForCheck();
      }

      this.detailGrids.push(detailGrid);
    }
  }

  refresh(params: any): boolean {
    this.cdr.markForCheck();
    return false;
  }

  refreshGrid(detailGrid: any) {
    detailGrid.getGridRows(
      this.params,
      (rows: any[], cols?: any[]) => {
        if (cols && !detailGrid.gridCols?.length) {
          detailGrid.gridCols = cols;
        }
        detailGrid.gridRows = rows || [];
        detailGrid.lastUpdateDate = new Date();
        detailGrid.lastUpdateFailed = false;

        if (detailGrid.expandFirstRow && detailGrid.gridRows?.length) {
          setTimeout(() => {
            this.grids.forEach((grid: GridComponent) => {
              grid.agGrid.api.getDisplayedRowAtIndex(0).setExpanded(true);
            });
          }, 100);
        }

        const selectedRowKey = detailGrid.selectedRowKey && typeof detailGrid.selectedRowKey === 'function' ? detailGrid.selectedRowKey(this.params) : detailGrid.selectedRowKey;
        if (selectedRowKey) {
          setTimeout(() => {
            this.grids.forEach((grid: GridComponent) => {
              grid.agGrid.api.forEachNode(node => {
                if (node.data.guidId === selectedRowKey) node.setSelected(true);
              });
            });
          }, 250);
        }

        this.cdr.markForCheck();
      },
      (error: any) => {
        detailGrid.lastUpdateFailed = true;
        this.cdr.markForCheck();
      }
    );
  }

  onGridReady(params: any) {
    for (const detailGrid of this.detailGrids || []) {
      const detailGridId = `detail_${(detailGrid.label || '')}_${this.masterRowIndex}`;
      const gridInfo = {
        id: detailGridId,
        api: params.api,
        columnApi: params.columnApi,
      };
      this.masterGridApi.addDetailGridInfo(detailGridId, gridInfo);
    }
  }

  ngOnDestroy(): void {
    for (const detailGrid of this.detailGrids || []) {
      const detailGridId = `detail_${(detailGrid.label || '')}_${this.masterRowIndex}`;
      this.masterGridApi.removeDetailGridInfo(detailGridId);
    }
  }

}
