import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';
import { DictNumber } from 'app/shared/models';

export enum WorkScheduleAbsenceReason {
  Unknown = 0,
  OtherAbsence = 1,
  Sickness = 2,
  CareForSickChildren = 3,
  OnLeave = 4,
  Vaccation = 5,
  CompensatoryLeave = 6,
  Millitary = 7,
  PregnancyAllowance = 8,
  ParentalLeave = 9,
  FathersDays = 10,
  WorkInjury = 11,
  ReductionOfWorkingHours = 12,
  LaidOff = 13,
}
export class WorkScheduleAbsence {
  guidId: string;
  sourceGuidId: string;
  reason: WorkScheduleAbsenceReason;
  startTimeIndex: number;
  endTimeIndex: number;
  totalHours: number;
  fromRequest: boolean;
  notified: boolean;

  constructor(item?: Partial<WorkScheduleAbsence>) {
    Object.assign(this, item);
  }
}
export class WorkScheduleDayItem {
  guidId: string;
  sourceGuidId: string;
  name: string;
  timeCode: string;
  startTimeIndex: number;
  endTimeIndex: number;
  totalHours: number;

  constructor(item?: Partial<WorkScheduleDayItem>) {
    Object.assign(this, item);
  }
}
export class WorkScheduleDay {
  guidId: string;
  notDefined: boolean;
  weekDayIndex: number;
  fromSchedule: boolean;
  scheduleDayStartTimeIndex: number;
  scheduleDayEndTimeIndex: number;
  scheduleTotalWorkHours: number;
  scheduleLunchHours: number;
  lunchHours: number;
  /** @deprecated */ dayStartTime?: string;
  /** @deprecated */ dayEndTime?: string;
  dayStartTimeIndex: number;
  dayEndTimeIndex: number;
  totalWorkHours: number;
  totalAbsenceHours: number;
  items: WorkScheduleDayItem[];
  absences: WorkScheduleAbsence[];

  constructor(item?: Partial<WorkScheduleDay>) {
    Object.assign(this, item);
  }
}

export class WorkSchedule {
  name: string;
  version: string;
  sysVersion: string;
  versionDateTime: string;
  days: DictNumber<WorkScheduleDay>;
}

export class WebWorkSchedule extends WebObject {

  static typeGuidId = 'f185d2ab-48ff-4fb9-b783-a14acce2351c';

  members: {
    name: string;
    version: string;
    sysVersion: string;
    versionDateTime: string;
    workSchedule: WorkSchedule;
  }

  relations: {
    user: WebRelationPointer;
  }

  constructor(item?: Partial<WebWorkSchedule>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebWorkSchedule.typeGuidId;
  }

}