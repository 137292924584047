export class WebBaseAddress {

  name: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;
  geoJsonPosition: any;
  addressStringProcessed: boolean;
  addressString: string;
  extraInformation: string;

  constructor(item?: Partial<WebBaseAddress>) {
    Object.assign(this, item);
  }

}