import { IToolPanelAngularComp } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebObject, WebResponse } from 'app/center-v2/shared/models';
import { WebViewDesign } from 'app/center-v2/core/view-system/models/web-view-design.model';
import { WebViewMemberValueType } from 'app/center-v2/core/view-system/models/web-view-member-value-type.enum';
import { WebViewRelativeDateFilterType } from 'app/center-v2/core/view-system/models/web-view-relative-date-filter-type.enum';
import { GenericService } from 'app/center-v2/shared/services';
import { TypeEnum } from 'app/shared/models';
import { AppService } from 'app/shared/services';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { SelectItem } from 'primeng/api';
import { mergeMap } from 'rxjs/operators';
import { BaseComponent } from '../../base/base.component';


@Component({
  selector: 'lc-grid-toolpanel-admin-view-design',
  templateUrl: 'grid-toolpanel-admin-view-design.component.html',
  styleUrls: ['grid-toolpanel-admin-view-design.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridToolPanelAdminViewDesignComponent extends BaseComponent implements IToolPanelAngularComp {

  private params: any;

  isBusy: boolean;
  operatorItems: SelectItem[];
  viewDesignMemberItems: SelectItem[];
  viewDesign: WebViewDesign;
  cellStyleLogicArray: any[];
  relativeDateFilterArray: any[];
  relativeDateFilterTypeItems: SelectItem[];
  rowStyleLogic: any;

  constructor(
    appService: AppService,
    private cdr: ChangeDetectorRef,
    private genericService: GenericService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    super(appService);

    this.operatorItems = [
      { value: '=', label: '=' },
      { value: '<', label: '<' },
      { value: '<=', label: '<=' },
      { value: '>', label: '>' },
      { value: '>=', label: '>=' },
    ];

    this.relativeDateFilterTypeItems = [
      { value: WebViewRelativeDateFilterType.Today, label: 'Today' },
      { value: WebViewRelativeDateFilterType.CurrentWeek, label: 'Current Week' },
      { value: WebViewRelativeDateFilterType.CurrentMonth, label: 'Current Month' },
      { value: WebViewRelativeDateFilterType.CurrentYear, label: 'Current Year' },
      { value: WebViewRelativeDateFilterType.LastXDays, label: 'Last X Days' },
      { value: WebViewRelativeDateFilterType.LastXWeeks, label: 'Last X Weeks' },
      { value: WebViewRelativeDateFilterType.LastXMonths, label: 'Last X Months' },
      { value: WebViewRelativeDateFilterType.LastXYears, label: 'Last X Years' },
    ];
  }

  // The init(params) method is called on the tool panel once upon component initialisation.
  agInit(params: any): void {
    this.params = params;
    this.viewDesign = this.params.viewDesign;
    this.viewDesignMemberItems = (this.viewDesign?.getRelation('members') as WebObject[] || [])
    .sort((a, b) => {
      return a.getSubType('WebAgGridViewDesignMember')?.members?.order - b.getSubType('WebAgGridViewDesignMember')?.members?.order;
    })
    .map((wo: WebObject) => {
      return { value: wo.guidId, label: wo.members.name };
    });

    this.refresh();
  }

  refresh() {
    const agGridState = JSON.parse(this.viewDesign?.subTypes?.WebAgGridViewDesign?.members?.defaultStateJson || '{}');
    this.cellStyleLogicArray = Object.values(agGridState?.cellStyleLogic || {});
    this.cellStyleLogicArray.push({});
    this.rowStyleLogic = agGridState?.rowStyleLogic || {};

    this.relativeDateFilterArray = (this.viewDesign?.getRelation('members') as WebObject[] || [])
    .filter((wo: WebObject) => {
      return wo.members.valueType === WebViewMemberValueType.Date || wo.members.valueType === WebViewMemberValueType.DateTime;
    })
    .sort((a, b) => {
      return a.getSubType('WebAgGridViewDesignMember')?.members?.order - b.getSubType('WebAgGridViewDesignMember')?.members?.order;
    })
    .map((wo: WebObject) => {
      return {
        value: wo.guidId,
        label: wo.members.name,
        filterModel: agGridState.filterModel?.[wo.guidId] || {},
      };
    });

    this.cdr.markForCheck();
  }

  clearViewDesignState() {
    this.params.columnApi.resetColumnState();
    this.params.api.setFilterModel({});

    this.cdr.markForCheck();
  }

  saveViewDesignState() {
    this.isBusy = true;

    const agGridState = {
      columnState: JSON.parse(JSON.stringify(this.params.columnApi.getColumnState())),
      filterModel: Object.assign(JSON.parse(JSON.stringify(this.params.api.getFilterModel())), this.getRelativeDateFilterModel()),
      cellStyleLogic: this.getCellStyleLogic(),
      rowStyleLogic: this.rowStyleLogic,
    };

    console.log(agGridState);

    let request = null;
    const subType = this.viewDesign.subTypes.WebAgGridViewDesign;
    if (subType) {
      subType.members.defaultStateJson = agGridState;
      request = this.genericService.update([this.viewDesign]);
    } else {
      request = this.genericService.newDraftSubType(this.viewDesign.guidId, TypeEnum.ViewDesignAgGrid)
      .pipe(
        mergeMap((response: WebResponse) => {
          const subType = response.getWebObjectSubType('WebAgGridViewDesign') as WebObject;
          subType.members.defaultStateJson = agGridState;
          this.viewDesign.subTypes.WebAgGridViewDesign = subType;
          return this.genericService.update([this.viewDesign]);
        })
      );
    }

    request.subscribe(() => {
      this.notificationService.success(
        this.translateService.instant('Success'),
        this.translateService.instant('ViewDesign state saved successfully.'),
      );

      this.isBusy = false;
      this.cdr.markForCheck();
    }, (error: any) => {
      this.isBusy = false;
      this.cdr.markForCheck();
    });
  }

  updateCellStyleMappings() {
    this.cellStyleLogicArray = (this.cellStyleLogicArray || [])
    .filter((item: any) => {
      return item.memberGuidId;
    });

    for (const cellSyleItem of this.cellStyleLogicArray) {
      cellSyleItem.colorMappings = (cellSyleItem.colorMappings || [])
      .filter((item: any) => {
        return item.value != null && item.value !== '';
      });

      cellSyleItem.colorMappings.push({ operator: '=', value: '', backgroundColor: '#ffffff', color: '#000000' });
    }

    this.cellStyleLogicArray.push({});

    this.cdr.markForCheck();
  }

  applyCellStyleLogic() {
    if (this.params.applyCellStyleLogic) {
      this.params.applyCellStyleLogic(this.getCellStyleLogic());
    }
  }

  private getCellStyleLogic() {
    return this.cellStyleLogicArray.reduce((previousValue: any, currentValue: any) => {
      if (currentValue.memberGuidId) {
        previousValue[currentValue.memberGuidId] = currentValue;
      }
      return previousValue;
    }, {});
  }

  updateRowStyleMappings() {
    if (this.rowStyleLogic?.memberGuidId) {
      this.rowStyleLogic.colorMappings = (this.rowStyleLogic.colorMappings || [])
      .filter((item: any) => {
        return item.value != null && item.value !== '';
      });

      this.rowStyleLogic.colorMappings.push({ operator: '=', value: '', backgroundColor: '#ffffff', color: '#000000' });
    } else {
      delete this.rowStyleLogic.colorMappings;
      delete this.rowStyleLogic.memberGuidId;
    }

    this.cdr.markForCheck();
  }

  applyRowStyleLogic() {
    if (this.params.applyRowStyleLogic) {
      this.params.applyRowStyleLogic(this.rowStyleLogic);
    }
  }

  applyRelativeDateFilterModel() {
    if (this.params.applyRelativeDateFilterModel) {
      this.params.applyRelativeDateFilterModel(this.getRelativeDateFilterModel());
    }
  }

  private getRelativeDateFilterModel() {
    return this.relativeDateFilterArray.reduce((previousValue: any, currentValue: any) => {
      if (currentValue.value) {
        previousValue[currentValue.value] = currentValue.filterModel?.type ? JSON.parse(JSON.stringify(currentValue.filterModel)) : undefined;
        if (previousValue[currentValue.value]) {
          previousValue[currentValue.value].filterType = 'date-relative';
        }
      }
      return previousValue;
    }, {});
  }

}