import { Injectable } from '@angular/core';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessSiteTree } from '../models/access-site-tree.model';


@Injectable({
  providedIn: 'root'
})
export class AccessSiteTreeService {

  private readonly urlSuffixPlaceholder = 'centerv2/accesssitetree/{what}';

  private accessSiteTree: AccessSiteTree;

  constructor(
    private apiService: ApiCenterV2Service,
  ) { }

  getTreeForUser(): Observable<AccessSiteTree> {
    if (this.accessSiteTree) return of(this.accessSiteTree);

    return this.apiService.post(
      this.urlSuffixPlaceholder.replace('{what}', 'gettreeforuser'),
      {
      },
    ).pipe(
      map((response: any) => {
        this.accessSiteTree = response && !response.noAccessSiteTree ? new AccessSiteTree(response.accessSiteTree) : null;
        return this.accessSiteTree;
      })
    );
  }

}
