import { DictString } from 'app/shared/models';


export class AccessSiteTreeSite {

  parentGuidId: string;
  guidId: string;
  environmentGuidId: string;
  level: 0;

  name: string;
  shortName: string;
  environmentGuidIds: string[];
  subSites: string[];
  tick: number;

  constructor(item?: Partial<AccessSiteTreeSite>) {
    Object.assign(this, item);
  }

}
