import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';

export enum WorkScheduleDayStatus {
  Unknown = 0,
  Planned = 1,
  Started = 2,
  Ended = 3,
  Completed = 10,
  Archived = 20,
}

export class WebWorkScheduleDefinedDay extends WebObject {

  static typeGuidId = '4762576a-2806-4bf1-a307-ed454a751eb3';

  members: {
    environmentUserGuidId: string;
    workScheduleGuidId: string;
    dayIndex: number;
    workScheduleDay: string;
    status: WorkScheduleDayStatus;
    endedDayProccssed: boolean;
    externalStatus: number;
    dayLocked: boolean;
    createdSource: string;
    endedSource: string;
    fromSchedule: boolean;
    scheduleStartTimeIndex: number;
    scheduleEndTimeIndex: number;
    scheduleTotalWorkHours: number;
    scheduleLunchHours: number;
    startTimeIndex: number;
    endTimeIndex: number;
    totalWorkHours: number;
    lunchHours: number;
    totalAbsenceHours: number;
    migratedTick: string;
    pausedTimeIndex: number;
    pausedEndTimeIndex: number;
    endTimeFromSystem: boolean;
  };

  relations: {
    environmentUser?: WebRelationPointer;
    workSchedule?: WebRelationPointer;
    definedMonth?: WebRelationPointer;
    definedWeek?: WebRelationPointer;
    absences?: WebRelationPointer[];
    items?: WebRelationPointer[];
  };

  constructor(item?: Partial<WebWorkScheduleDefinedDay>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebWorkScheduleDefinedDay.typeGuidId;
  }

}