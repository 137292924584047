export class ViewSolutionEvent {
  guidId: string;
  deviceEventSolutionGuidId: string;
  eventGuidId: string;
  eventName: string;
  eventSysVersion: number;
  environmentGuidId: string;
  siteGuidId: string;
  siteName: string;
  deviceGuidId: string;
  deviceId: string;
  solutionDeviceUserGuidId: string;
  solutionDeviceUserName: string;
  tickDateTime: string;
  values: any;

  constructor(item?: any) {
    Object.assign(this, item);
  }

}
