import { FilterStringFilter } from './filter-string-filter.model';
import { SolutionTypeEnum, SolutionTypeMember } from 'app/shared/models';
import { FilterStringFilterGroup } from './filter-string-filter-group.model';

export class ObjectMemberValueFilter implements FilterStringFilter {

  static readonly filterTypeGuidId: string = 'fff3cb32-7f51-431a-a16a-a17ed771d44d';
  readonly filterTypeGuidId: string = ObjectMemberValueFilter.filterTypeGuidId;

  filterGroups?: FilterStringFilterGroup[];
  isGroup: boolean;

  memberTypeGuidId: string;
  member: SolutionTypeMember;
  operator?: string;
  value?: any;
  wildcardFilter: boolean;

  constructor(item?: Partial<ObjectMemberValueFilter>) {
    Object.assign(this, item);

    this.filterGroups = (this.filterGroups || []).map((x: FilterStringFilterGroup) => {
      return new FilterStringFilterGroup(x);
    });

    this.operator = this.operator || '=';
    // this.value = this.value || '';
  }

  toJSON() {
    const obj = Object.assign({}, this);

    Object.assign(obj, {
      memberGuidId: this.member.centerTypeMemberGuidId || this.member.guidId,
      memberId: this.member.$memberId,
      wildcardFilter: ['contains', '!contains'].indexOf(this.operator || '') >= 0,
      incaseSensetive: true,
      specialMemberType: undefined,
      quotation: [
        SolutionTypeEnum.DateTime.toString(),
        SolutionTypeEnum.Guid.toString(),
        SolutionTypeEnum.Json.toString(),
        SolutionTypeEnum.String.toString(),

      ].indexOf(this.member.baseSolutionTypeGuidId) >= 0,
      checkDefined: false,
    });

    return obj;
  }

}