import { SolutionObject } from './solution-object.model';
import { ViewData } from './view-data.model';
import { ViewSolutionEvent } from './view-solution-events.model';


export class ViewDataResponse<T> {

  webViewDataSources?: ViewData<T>[]; // this was replaced in newer calls with "solutionObjects"
  solutionObjects?: SolutionObject[]; // this replaced "webViewDataSources" in newer calls
  viewSolutionEvents?: ViewSolutionEvent[];

  viewTick: number;
  workerTick: number;
  workerFinal: boolean;

  constructor(item?: Partial<ViewDataResponse<T>>) {
    Object.assign(this, item);

    this.webViewDataSources = (this.webViewDataSources || []).map((vd: ViewData<T>) => {
      return new ViewData(vd);
    });
    this.solutionObjects = (this.solutionObjects || []).map((so: SolutionObject) => {
      return new SolutionObject(so);
    });
    this.viewSolutionEvents = (this.viewSolutionEvents || []).map((vse: ViewSolutionEvent) => {
      return new ViewSolutionEvent(vse);
    });
  }

}
