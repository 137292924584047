import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from 'app/shared/utils';
import { SiteTree, TypeEnum } from '../../../../shared/models';
import { PreemOrderStatus } from '../preem-order-status.enum';
import { SolutionObject } from './solution-object.model';

export const enum ObservingType {
  ObserveEmpty = 0,
  ObserveFull = 1,
}

export class ViewTank {
  tankId: string;
  name: string;
  gpsLocation: string;
  mobileTank: boolean;
  tankVolume: number;
  level: number;
  effectiveLevel: number;
  effectiveTankVolume: number;
  visualTankVolume: number;
  freeVolume: number;
  overfillLevel: number;
  emptyLevel: number;
  alarmLevel: number;
  orderLevel: number;
  volume: number;
  outOfService: boolean;
  deviceDateTime: string;
  noCommunication: boolean;
  deliveryDepotNo: number;
  customerNo: string;
  city: string;
  product: string;
  productUnit: string;
  productShortUnit: string;
  salesNo: number;
  storageNo: number;
  traceOrderCreated: boolean;
  siteTree: SiteTree;
  activeOrder: PreemOrderStatus;
  predictedEmptyDateTime: string;
  predictedEmptyToLevel: number;
  filledDateTime: string;
  filledToLevel: number;
  filledAvgConsumption: number;
  avgDaysBetweenOrder: number;
  lastOrderDateTime: string;

  allowCreateOrder: boolean;
  observingType: ObservingType;
  avgConsumptionV2: boolean;

  comments: string;

  'address.Name': string;
  'address.Address1': string;
  'address.Address2': string;
  'address.ZipCode': string;
  'address.Country': string;

  traces: any[];

  constructor(item?: any) {
    Object.assign(this, item);
  }

  calculateExtraValues() {
    const suffixShortUnit = ' ' + (this.productShortUnit ? this.productShortUnit : '');
    (this as any).overfillVolume = ~~(this.tankVolume * (this.overfillLevel / 100));
    (this as any).emptyVolume = ~~(this.tankVolume * (this.emptyLevel / 100));
    (this as any).effectiveTankVolume = ~~(this.tankVolume - (this as any).overfillVolume - (this as any).emptyVolume);
    (this as any).effectiveVolume = (this.effectiveLevel / 100) * (this as any).effectiveTankVolume;
    (this as any).$effectiveVolume = ~~((this as any).effectiveVolume) + suffixShortUnit;
    (this as any).$deviceDateTime = new Date(this.deviceDateTime);
  }

  static fromCaseInsensitiveValues(values: any) {
    const viewTank = new ViewTank({
      tankId: values.tankid,
      name: values.name,
      gpsLocation: values.gpslocation,
      mobileTank: values.mobiletank,
      tankVolume: values.tankvolume,
      level: values.level,
      effectiveLevel: values.effectivelevel,
      visualTankVolume: values.visualtankvolume,
      freeVolume: values.freevolume,
      overfillLevel: values.overfilllevel,
      emptyLevel: values.emptylevel,
      alarmLevel: values.alarmlevel,
      orderLevel: values.orderlevel,
      volume: values.volume,
      outOfService: values.outofservice,
      deviceDateTime: values.devicedatetime,
      noCommunication: values.nocommunication,
      deliveryDepotNo: values.depotno,
      customerNo: values.customerno,
      city: values.city,
      product: values.product,
      productUnit: values.productunit,
      productShortUnit: values.productshortunit,
      salesNo: values.salesno,
      storageNo: values.storageno,
      traceOrderCreated: values.traceordercreated,
      predictedEmptyDateTime: values.predictedemptydatetime,
      predictedEmptyToLevel: values.predictedemptytolevel,
      filledDateTime: values.filleddatetime,
      filledToLevel: values.filledtolevel,
      filledAvgConsumption: values.filledavgconsumption,
      avgConsumptionV2: values.avgconsumptionv2,
      observingType: null, // this comes from a relation TopFuel Service...
    } as ViewTank);

    viewTank.calculateExtraValues();
    return viewTank;
  }


  static fromSolutionObject(tankSO: SolutionObject, translateService: TranslateService) {
    let viewTank = {
      guidId: tankSO.guidId,
      typeGuidId: TypeEnum.Tank,
      values: {} as any
    };

    const suffixShortUnit = ' ' + tankSO.getValue(TankFieldEnum.ProductShortUnit, '');
    viewTank.values.tankId = tankSO.getValue(TankFieldEnum.TankId);
    viewTank.values.name = tankSO.getValue(TankFieldEnum.Name);
    viewTank.values.tankVolume = tankSO.getValue(TankFieldEnum.TankVolume, 0);
    viewTank.values.$tankVolume = ~~viewTank.values.tankVolume + suffixShortUnit;
    viewTank.values.effectiveLevel = tankSO.getValue(TankFieldEnum.EffectiveLevel, 0);
    viewTank.values.$effectiveLevel = ~~viewTank.values.effectiveLevel;
    viewTank.values.overfillLevel = tankSO.getValue(TankFieldEnum.OverfillLevel, 0);
    viewTank.values.overfillVolume = ~~(viewTank.values.tankVolume * (viewTank.values.overfillLevel / 100));
    viewTank.values.emptyLevel = tankSO.getValue(TankFieldEnum.EmptyLevel, 0);
    viewTank.values.emptyVolume = ~~(viewTank.values.tankVolume * (viewTank.values.emptyLevel / 100));

    viewTank.values.effectiveTankVolume = ~~(viewTank.values.tankVolume - viewTank.values.overfillVolume - viewTank.values.emptyVolume);
    viewTank.values.effectiveVolume = (viewTank.values.effectiveLevel / 100) * viewTank.values.effectiveTankVolume;
    viewTank.values.$effectiveVolume = ~~(viewTank.values.effectiveVolume) + suffixShortUnit;
    viewTank.values.freeVolume = tankSO.getValue(TankFieldEnum.FreeVolume, 0);
    viewTank.values.$freeVolume = ~~viewTank.values.freeVolume + suffixShortUnit;
    viewTank.values.alarmLevel = tankSO.getValue(TankFieldEnum.AlarmLevel, 0);
    viewTank.values.orderLevel = tankSO.getValue(TankFieldEnum.OrderLevel, 0);
    viewTank.values.level = tankSO.getValue(TankFieldEnum.Level, 0);
    viewTank.values.outOfService = tankSO.getValue(TankFieldEnum.OutOfService);
    viewTank.values.deviceDateTime = DateUtils.getLocalISOString(tankSO.getValue(TankFieldEnum.TankDeviceDateTime), false);
    viewTank.values.$deviceDateTime = viewTank.values.deviceDateTime ? new Date(viewTank.values.deviceDateTime) : undefined;
    viewTank.values.noCommunication = DateUtils.daysDiffNoRound(new Date(tankSO.getValue(TankFieldEnum.TankDeviceDateTime)), new Date()) >= 1.0;
    viewTank.values.product = tankSO.getValue(TankFieldEnum.ProductName);
    viewTank.values.productShortUnit = tankSO.getValue(TankFieldEnum.ProductShortUnit);
    viewTank.values.city = tankSO.getValue(TankFieldEnum.City);
    viewTank.values.salesNo = tankSO.getValue(TankFieldEnum.SalesNo);
    viewTank.values.deliveryDepotNo = tankSO.getValue(TankFieldEnum.DeliveryDepotNo);
    viewTank.values.customerNo = `${tankSO.getValue(TankFieldEnum.SiteReceiverNo, '-')}-${tankSO.getValue(TankFieldEnum.TankReceiverNo, '-')}`.replace('--', '');
    viewTank.values.allowCreateOrder = tankSO.getValue(TankFieldEnum.BasicOrder) && !tankSO.getValue(TankFieldEnum.ActiveOrderGuidId);
    viewTank.values.activeOrderGuidId = tankSO.getValue(TankFieldEnum.ActiveOrderGuidId);
    viewTank.values.activeOrderStatus = this.getOrderStatusName(tankSO.getValue(TankFieldEnum.ActiveOrder), translateService);
    viewTank.values.observingType = tankSO.getValue(TankFieldEnum.ObservingType) || 0;
    viewTank.values.mobileTank = tankSO.getValue(TankFieldEnum.MobileTank, false);
    viewTank.values.gpsLocation = tankSO.getValue(TankFieldEnum.GpsLocation);
    viewTank.values.gpsDateTime = viewTank.values.deviceDateTime;

    viewTank.values.$status = `${viewTank.values.outOfService ? 'outOfService' : 'inService'}${viewTank.values.noCommunication ? '|noCommunication' : ''}`;

    return viewTank;
  }

  private static getOrderStatusName(value: PreemOrderStatus, translateService: TranslateService): string {
    if (value) {
      switch (parseInt(value.toString(), 10)) {
        case PreemOrderStatus.Cancelled:
          return translateService.instant('Cancelled');
        case PreemOrderStatus.Completed:
          return translateService.instant('Completed');
        case PreemOrderStatus.Confirmed:
          return translateService.instant('Confirmed');
        case PreemOrderStatus.Delivered:
          return translateService.instant('Delivered');
        case PreemOrderStatus.New:
          return translateService.instant('New');
        case PreemOrderStatus.OrderRequest:
          return translateService.instant('Order Request');
        case PreemOrderStatus.Planned:
          return translateService.instant('Planned');
        case PreemOrderStatus.Requested:
          return translateService.instant('Requested');
        default:
          return value.toString();
      }
    } else {
      return '';
    }
  }

}

enum TankFieldEnum {
  TankId = '3d40598f-ada7-4af3-bc28-94ba8fff0413',
  Name = '8418838f-66ee-4ff8-b2b9-7f87b7f58cdf',
  VisualTankVolume = '5d3055c9-5f0f-4dd3-b573-41d34150dcff',
  GpsLocation = '1cdf6982-35af-4c0c-92e9-24b91d7fde86',
  MobileTank = 'd4f9a62f-bb21-4f8f-8c70-c3d92ef9d58a',
  EffectiveLevel = 'c0b60d50-2fbf-4b7b-a118-93baaa5d8a1d',
  OverfillLevel = '8dc22ff3-cbef-4a25-8c51-ba96612032cf',
  EmptyLevel = '2d591b44-8b3f-4565-9a76-078c3d44e829',
  TankVolume = 'de1c54a2-38d9-4f51-865d-87cd775c06fe',
  FreeVolume = '81f510f9-a352-469d-897b-ae434ec460ae',
  AlarmLevel = '0d210862-1df6-43b4-8d27-4e43aaf8d51c',
  OrderLevel = 'bac497d0-02c0-4703-865e-a4c1bb8a2f44',
  Level = '63ddad30-10ba-43f4-b75a-3072efb6b299',
  TankModelName = '40b5b14d-54a7-464c-a48b-ca12a6343c2a',
  TankDeviceDateTime = '10124611-1afd-4566-b092-2f2f1843389a',
  OutOfService = '2b11b299-615d-46a8-862a-5bfebad75c19',
  City = '042977c5-2c94-4cc3-99ab-99ae24722f6c',
  ProductName = '4232be8b-7812-4c90-bfba-14d6c1a095ce',
  ProductUnit = 'cc3116a4-5e0b-4639-bc6e-739d687de551',
  ProductShortUnit = '34da9af4-f3c4-43fe-a048-52c6638deccd',
  SalesNo = 'c42058a5-b365-43c9-a997-c534adb19395',
  StorageNo = '42a7a24c-a36c-4744-a3d4-caaf31d40af2',
  BasicOrder = 'fccdcd07-a928-4d41-b4c8-a84456bd9204',
  ObservingType = 'd01c9202-1ade-4a51-9c86-089e53f4ecd4',
  ActiveOrderGuidId = '3f89db04-875f-4d52-b52a-c2dc31e89f0a',
  ActiveOrder = '7fe4480f-15bb-45da-aac6-e3dcb9d650dd',
  DeliveryDepotNo = '5befad57-b21c-40a5-b88a-f5a4507b0518',
  TankReceiverNo = 'e520d99e-2af6-4caa-a223-a0d5e317393e',
  SiteReceiverNo = '17027352-b616-491a-9b30-de38327a5c04',
};
