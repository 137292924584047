import { WebCenterType } from 'app/center-v2/shared/models';
import { WebEnvironmentSite } from 'app/center-v2/shared/models/web-center-types/web-environment-site.model';
import { WebBaseSite } from './web-base-site.model';
import { WebSite } from 'app/center-v2/shared/models/web-center-types/web-site.model';

export class StructuralAdminSiteResponse {

  web2BaseSite: WebBaseSite;
  web2Site: WebSite;
  web2EnvironmentSite: WebEnvironmentSite;
  web2CenterTypes: WebCenterType[];

  constructor(item?: Partial<StructuralAdminSiteResponse>) {
    Object.assign(this, item);

    this.web2BaseSite = this.web2BaseSite ? new WebBaseSite(this.web2BaseSite) : undefined;
    this.web2EnvironmentSite = this.web2EnvironmentSite ? new WebEnvironmentSite(this.web2EnvironmentSite) : undefined;

    this.web2CenterTypes = (this.web2CenterTypes || []).map(x => new WebCenterType(x));
  }

}
