<p-dialog appendTo="body"
  header="Solution Features"
  styleClass="solution-features-dialog"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="false"
  [closable]="false"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="isVisible">

  <div class="container">
    <p-listbox class="list"
      optionLabel="name"
      [options]="solutionFeatures"
      [(ngModel)]="selectedSF">
    </p-listbox>

    <p-button class="new-button"
      styleClass="ui-button-primary"
      label="Add New"
      (click)="newSolutionFeature()">
    </p-button>

    <p-tabView *ngIf="selectedSF"
      class="editor">
      <p-tabPanel header="General">
        <div class="tab-panel">
          <div class="hint">
            Here you can edit the solution feature name and default values.
          </div>

          <br>

          <div class="readonly-form section">
            <div class="row">
              <div class="label mw-40">
                Name:
              </div>

              <div class="value">
                <input pInputText
                  type="text"
                  class="input"
                  name="name"
                  [(ngModel)]="selectedSF.name"
                  required />
              </div>
            </div>

            <div class="row">
              <div class="label mw-40">
                Feature Name:
              </div>

              <div class="value">
                <input pInputText
                  type="text"
                  class="input"
                  name="featureName"
                  [(ngModel)]="selectedSF.featureName"
                  required />
              </div>
            </div>

            <div class="row">
              <div class="label mw-40">
                Default Value:
              </div>
              <div class="value">
                <p-dropdown
                  appendTo="body"
                  name="defaultValue"
                  [autoDisplayFirst]="false"
                  [options]="defaultValueItems"
                  [(ngModel)]="selectedSF.defaultValue"
                  required>
                </p-dropdown>
              </div>
            </div>

            <!--<div class="row">
              <div class="label mw-40">
                Default Complex Value:
              </div>
              <div class="value">
                <input pInputText
                  type="text"
                  class="input"
                  name="defaultComplexValue"
                  [(ngModel)]="selectedSF.defaultComplexValue"
                  required />
              </div>
            </div>-->
          </div>
        </div>

        <div class="footer guid">
          {{ selectedSF.guidId }}
        </div>
      </p-tabPanel>
    </p-tabView>

    <p-button *ngIf="selectedSF"
      class="save-button"
      styleClass="ui-button-success"
      [label]="'Save' | translate"
      (click)="save(selectedSF)">
    </p-button>
  </div>

  <p-footer class="flex-row gap-1 middle">
    <div class="flex-1"></div>
    <p-button styleClass="ui-button-secondary" [label]="'Close' | translate" (click)="closeClick()"></p-button>
  </p-footer>
</p-dialog>

<new-solution-feature-dialog></new-solution-feature-dialog>
