import { FilterStringFilterGroup } from './filter-string-filter-group.model';
import { ObjectMemberValueFilter } from './object-member-value-filter.model';


export interface IFilterStringFilter {

  readonly filterTypeGuidId: string;

  filterGroups?: FilterStringFilterGroup[];
  isGroup: boolean;

}

export class FilterStringFilter implements IFilterStringFilter {

  readonly filterTypeGuidId: string;

  filterGroups?: FilterStringFilterGroup[];
  isGroup: boolean;

  constructor(item?: Partial<IFilterStringFilter>) {
    switch (item?.filterTypeGuidId) {
      case ObjectMemberValueFilter.filterTypeGuidId:
        Object.assign(this, new ObjectMemberValueFilter(item));
        break;
      default:
        Object.assign(this, item);

        this.filterGroups = (this.filterGroups || []).map(x => new FilterStringFilterGroup(x));
    }
  }

  toJSON() {
    switch (this.filterTypeGuidId) {
      case ObjectMemberValueFilter.filterTypeGuidId:
        return (new ObjectMemberValueFilter(this as any)).toJSON();
      default:
        return this;
    }
  }
}
