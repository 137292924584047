<div class="search-content" [ngStyle]="{ 'width': width + 'px' }">
  <ngx-loading [show]="!!searchTreeSubscription"></ngx-loading>

  <div class="header">
    <lc-search-input #input
      [(ngModel)]="query"
      (keyup.enter)="searchTree()">
    </lc-search-input>
  </div>

  <div class="results">
    <div *ngIf="!result || !result.webDataSources"
      class="ta-center">
      {{'No results found.' | translate}}
    </div>

    <div *ngIf="result && result.webDataSources"
      class="flex-col center">
      <lc-button *ngFor="let item of result.webDataSources"
        class="link-button"
        (click)="selectItem(item)"
        [icon]="item.designTreeNodeIconUri"
        iconPos="left"
        [label]="item.values.tankid || item.values.name"
        type="clear">
      </lc-button>
    </div>
  </div>
</div>
