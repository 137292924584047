import { DictString, SiteGuidIdAndName } from 'app/shared/models';
import { GuidUtils } from 'app/shared/utils';
import { AccessSiteTreeSite } from './access-site-tree-site.model';


export class AccessSiteTree {

  guidId: string;
  environmentGuidId: string;
  accessTreeSiteTree: boolean;
  siteGuidId: string;
  centerTypeGuidId: string;
  siteAccessAttributes: number;
  objectAccessAttributes: number;
  environmentGuidIds: string[];
  sites: DictString<AccessSiteTreeSite>;
  tick: number;
  token: string;

  constructor(item?: Partial<AccessSiteTree>) {
    Object.assign(this, item);

    for (const siteGuidId of Object.keys(this.sites || {})) {
      this.sites[siteGuidId] = new AccessSiteTreeSite(this.sites[siteGuidId]);
    }
  }

  getPathForSiteGuidId(siteGuidId: string): AccessSiteTreeSite[] {
    const result = [];

    let site = this.sites[siteGuidId];
    if (!site) {
      const rootSite = Object.values(this.sites).find(s => s.level === 0 && GuidUtils.isNullOrEmpty(s.parentGuidId));
      result.splice(0, 0, rootSite);
    }
    while (site) {
      result.splice(0, 0, site);
      site = this.sites[site.parentGuidId];
    }

    return result;
  }

}
