export const environment = {
  name: 'zoey',
  production: true,

  apiStatusUrl: 'https://wapi.logiccenter.com:1810/', // StatusWebAPI Production
  apiCenterUrl: 'https://webapiv2.logiccenter.com/', // CenterWebAPI Production
  apiCenterV2Url: 'https://centerapiv2.logiccenter.com/', // CenterWebAPI V2 Production
  apiReportUrl: 'https://wapi.logiccenter.com:1814/', // ReportWebAPI Production
  apiStudioUrl: 'https://wapi.logiccenter.com:1812/', // StudioWebAPI Production

  agGridLicenseKey: 'CompanyName=MobileLogic Sweden AB,LicensedApplication=LogicCenter,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=3,AssetReference=AG-037261,SupportServicesEnd=24_March_2024_[v2]_MTcxMTIzODQwMDAwMA==b1787252782ac39d2263a355c31e3223',
  appInsightsKey: '7eccb8a9-49e7-4125-bb60-61bd1ac18701',
  goJsLicenseKey: '73f942e2ba6028a800ca0d2b113f69ed1bb37b349e821ef05e0041f5ef0c68402bc9ec7e58878e95d0ad4efa1d7ac2d8cfd56f2cc4190532b46387d841e686ace23276b71d0f43daa25326ca9afc28f5af7c75f6d0a571f78a7e8ca0bba9d18c5fe9f0d457c211bb2c',
  googleApiKey: 'AIzaSyA79VviLKkr9Ot5YETvKQZS8lTGsLDXZn0',
  sentryDNS: 'https://40ac81a42da643bfa57c14cad126f682@sentry.io/1323950',
};
