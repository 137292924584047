import { AgRendererComponent } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { BrowserUtils } from 'app/shared/utils';
import { BaseGridCellRenderer } from '../base/base-grid-cellrenderer.component';

@Component({
  selector: 'lc-grid-cellrenderer-text',
  templateUrl: 'grid-cellrenderer-text.component.html',
  styleUrls: ['grid-cellrenderer-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellRendererText extends BaseGridCellRenderer implements AgRendererComponent {

  readonly noValueHtml = `<span class="no-value">-</span>`;

  get isEditable(): boolean {
    return this.getParamValue(this.params.colDef?.editable);
  };
  params: any;

  value: any;

  constructor(
    cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    super(cdr);
  }

  agInit(params): void {
    this.params = params;

    this.params.cellClass = this.getParamValue(this.params.cellClass);
    this.params.disabled = this.getParamValue(this.params.disabled);
    this.params.iconLeft = this.getParamValue(this.params.iconLeft);
    this.params.iconLeftStyle = this.getParamValue(this.params.iconLeftStyle);
    this.params.iconRight = this.getParamValue(this.params.iconRight);
    this.params.iconRightStyle = this.getParamValue(this.params.iconRightStyle);
    this.params.isLink = this.getParamValue(this.params.isLink);
    this.params.options = this.getParamValue(this.params.options);
    // this.params.tooltip = this.getParamValue(this.params.tooltip);
    this.params.type = this.getParamValue(this.params.customControlType) || this.getParamValue(this.params.type);
    this.params.valueFormatted = this.getParamValue(this.params.valueFormatted);
    this.cdr.markForCheck();


    if (this.params.valueFormatted) {
      this.value = this.params.valueFormatted;
      return;
    }

    if (this.params.options && this.params.value != null && this.params.options.some(x => x.value == this.params.value)) {
      this.value = this.params.options.find(x => x.value == this.params.value).label;
    } else if (this.params.value && typeof this.params.value === 'string' && this.params.translate) {
      this.value = this.translateService.instant(this.params.value);
    } else if (this.params.value != null && this.params.type === 'number') {
      if (typeof this.params.value === 'string') {
        this.value = parseFloat(parseFloat(this.params.value).toFixed(3));
      } else {
        this.value = parseFloat(this.params.value.toFixed(3));
      }
    } else {
      this.value = this.params.value != null ? this.params.value : '';
    }
  }

  refresh(params: any): boolean {
    this.cdr.markForCheck();
    return false;
  }

  onClick(ev: Event) {
    if (!this.params?.click) return;

    ev.stopPropagation();

    this.params?.click && !this.params?.disabled ? this.params.click(this.params, ev) : null;
  }

  onDoubleClick(ev: Event) {
    if (!this.params?.doubleClick) return;

    ev.stopPropagation();

    this.params?.doubleClick && !this.params?.disabled ? this.params.doubleClick(this.params, ev) : null;
  }

  copyToClipboard(ev: Event) {
    ev.stopPropagation();

    BrowserUtils.copyToClipboard(this.value || '');

    this.notificationService.info(
      this.translateService.instant('Info'),
      this.translateService.instant('Content copied to the clipboard.'),
    );
  }

}
