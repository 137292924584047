import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { WebBaseSite } from 'app/center-v2/core/structural-admin/models/web-base-site.model';
import { WebBaseUser } from 'app/center-v2/core/structural-admin/models/web-base-user.model';
import { WebLoadMap, WebObject, WebResponse, WorkspaceSolutionType } from 'app/center-v2/shared/models';
import { BaseComponentV2 } from 'app/shared/components/base/base-v2.component';
import { BaseDialog, ConfirmDialog, PromptDialog } from 'app/shared/dialogs';
import { AppService, SessionService } from 'app/shared/services/app';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { GuidUtils, TreeUtils } from 'app/shared/utils';
import { MenuItem, TreeNode } from 'primeng/api';
import { StructuralAdminSiteResponse } from '../../../core/structural-admin/models/structural-admin-site-response.model';
import { StructuralAdminUserResponse } from '../../../core/structural-admin/models/structural-admin-user-response.model';
import { StructuralAdminService, UserUsage } from '../../../core/structural-admin/services/structural-admin.service';
import { AccessSiteTreeSite } from '../../models/access-site-tree-site.model';
import { AccessSiteTree } from '../../models/access-site-tree.model';
import { WebDesignTreeCustomObject, WebDesignTreeSite } from '../../models/web-base/web-design-tree.model';
import { WebEnvironmentUser } from '../../models/web-center-types/web-environment-user.model';
import { WebSite } from '../../models/web-center-types/web-site.model';
import { WebUser } from '../../models/web-center-types/web-user.model';
import { GenericService } from '../../services';
import { AccessSiteTreeService } from '../../services/access-site-tree.service';


@Component({
  selector: 'lc-access-site-tree',
  templateUrl: './access-site-tree.component.html',
  styleUrls: ['./access-site-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessSiteTreeComponent extends BaseComponentV2 implements OnInit {

  private readonly storageSelectedTreeNodePathKey = 'lc_selected_tree_node_path';

  @ViewChild(ConfirmDialog, { static: false }) confirmDialog: BaseDialog;
  @ViewChild(PromptDialog, { static: false }) promptDialog: BaseDialog;

  @Input() adminMode: boolean;
  @Input() set displayInTreeUserUsages(value: UserUsage[]) {
    this._displayInTreeUserUsages = value;
    if (this._displayInTreeUserUsages?.length) {
      this.fetchDisplayInTreesForExpandedSites();
    } else {
      this.refreshTree();
    }
  }
  get displayInTreeUserUsages(): UserUsage[] {
    return this._displayInTreeUserUsages;
  }
  private _displayInTreeUserUsages: UserUsage[];
  @Input() set selectedSiteGuidId(value: string) {
    this._selectedSiteGuidId = value;
    if (!this.treeNodes?.length) return;

    this.selectedTreeNode = this.flatTreeNodes.find(x => x.data.guidId === this._selectedSiteGuidId);
    this.selectedTreeNodeChange.emit(this.selectedTreeNode);
    this.cdr.markForCheck();
  }
  get selectedSiteGuidId(): string {
    return this._selectedSiteGuidId;
  }
  private _selectedSiteGuidId: string;
  @Input() solutionTypes: WorkspaceSolutionType[];

  @Output() selectedTreeNodeClickedAgain = new EventEmitter<TreeNode>();
  @Output() selectedTreeNodeChange = new EventEmitter<TreeNode>();

  flatTreeNodes: TreeNode[];

  longLivedBusy: boolean;

  contextMenuItems: MenuItem[];
  searchMode: boolean;
  searchTypeGuidIds: string[];
  selectedTreeNode: TreeNode;

  showTypesInTree: boolean;
  treeNodes: TreeNode[];
  treeWidth: number;

  cancelAnyActiveNavigation: boolean;

  constructor(
    private accessSiteTreeService: AccessSiteTreeService,
    private appService: AppService,
    cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private genericService: GenericService,
    private notificationService: NotificationService,
    sessionService: SessionService,
    private structuralAdminService: StructuralAdminService,
    private translateService: TranslateService,
  ) {
    super(cdr, sessionService);

    this.treeNodes = [];
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.emitBusy(true);

    this.accessSiteTreeService.getTreeForUser()
    .subscribe((ast: AccessSiteTree) => {
      this.treeNodes = this.buildSiteTree(ast);

      if (this.selectedSiteGuidId) this.selectedTreeNode = this.flatTreeNodes.find(x => x.data.guidId === this._selectedSiteGuidId);

      if (this._displayInTreeUserUsages?.length) {
        this.fetchDisplayInTreesForExpandedSites();
      } else {
        this.refreshTree();
      }

      this.emitBusy(false);
      this.cdr.markForCheck();
    }, (error: any) => {
      this.emitBusy(false);
      this.cdr.markForCheck();
    });
  }

  private buildSiteTree(ast: AccessSiteTree): TreeNode[] {
    this.flatTreeNodes = [];

    const treeNodes = [];
    if (!ast?.sites) return treeNodes;

    const allSites = Object.values(ast.sites);
    const rootSiteIndex = allSites.findIndex(s => s.level === 0 && GuidUtils.isNullOrEmpty(s.parentGuidId));
    const rootSite = rootSiteIndex >= 0 ? allSites.splice(rootSiteIndex, 1)[0] : null;
    if (!rootSite) {
      console.error('Root site not found!');
      return treeNodes;
    }

    const treeNode = this.buildSiteTreeNode(rootSite);
    treeNode.expanded = true;
    treeNodes.push(treeNode);
    this.flatTreeNodes.push(treeNode);

    for (let i = 0; i < allSites.length; i++) {
      const parentTreeNode = this.flatTreeNodes.find(x => x.data.guidId === allSites[i].parentGuidId);
      if (!parentTreeNode) continue;

      const site = allSites.splice(i, 1)[0];
      const treeNode = this.buildSiteTreeNode(site);
      parentTreeNode.children.push(treeNode);
      this.flatTreeNodes.push(treeNode);
      i = -1; // restart from 0
    }

    return treeNodes;
  }

  private buildSiteTreeNode(site: AccessSiteTreeSite) {
    return {
      data: {
        guidId: site.guidId,
        parentGuidId: site.parentGuidId,
        typeGuidId: WebSite.typeGuidId,
      },
      label: `${site.name} ${site.shortName ? ' (' + site.shortName + ')' : ''}`,
      icon: 'fas fa-home',
      leaf: false,
      children: []
    } as TreeNode;
  }

  nodeExpand(treeNode: TreeNode) {
    treeNode.expanded = true;
    this.selectedTreeNode = treeNode;
    this.selectedTreeNodeChange.emit(this.selectedTreeNode);

    if (!this.adminMode && !treeNode.children) {
      treeNode.leaf = true;
    }

    this.updateTreeWidth();
  }

  nodeSelect(treeNode: TreeNode, fetchData: boolean, userSelection?: boolean) {
    if (!treeNode) return;
    if (userSelection && treeNode === this.selectedTreeNode) {
      this.selectedTreeNodeClickedAgain.emit(this.selectedTreeNode);
      return;
    }

    this.selectedTreeNode = treeNode;
    this.selectedTreeNodeChange.emit(this.selectedTreeNode);
    this.cdr.markForCheck();

    if (fetchData && treeNode.data.guidId) {
      Sentry.configureScope((scope) => {
        scope.setExtra('treeNodeGuidId', treeNode.data.guidId);
        scope.setExtra('treeNodeTypeGuidId', treeNode.data.typeGuidId);
      });
      console.log(this.selectedTreeNode.data);

      if (this.adminMode) {
        // this.fetchAccessSiteTree(treeNode.data.guidId, treeNode);
      } else {
        this.selectedTreeNodeChange.emit(treeNode);
      }
    }

    if (this.adminMode) {
      this.contextMenuItems = this.buildContextMenuItems(treeNode);
    }
  }

  private buildContextMenuItems(treeNode: TreeNode): MenuItem[] {
    const result = [];

    if (treeNode.data.typeGuidId === WebSite.typeGuidId) {
      const siteSolutionType = (this.solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === WebSite.typeGuidId);
      result.push({
        label: this.translateService.instant('Add') + ' ' + this.translateService.instant('Site'),
        icon: 'fas fa-home',
        command: () => {
          this.addItem(treeNode, WebSite.typeGuidId, this.translateService.instant('Site'));
        },
        disabled: !siteSolutionType?.canAdd,
      });

      const userSolutionType = (this.solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === WebUser.typeGuidId);
      result.push({
        label: this.translateService.instant('Add') + ' ' + this.translateService.instant('User'),
        icon: 'far fa-user-circle',
        command: () => {
          this.addItem(treeNode, WebUser.typeGuidId, this.translateService.instant('User'));
        },
        disabled: !userSolutionType?.canAdd,
      });

      result.push({ separator: true });

      result.push({
        label: this.translateService.instant('Remove') + ' ' + this.translateService.instant('Site'),
        icon: 'fas fa-trash',
        command: () => {
          this.removeItem(treeNode);
        },
        disabled: !siteSolutionType?.canDelete,
      });
    } else if (treeNode.data.typeGuidId === WebUser.typeGuidId) {
      const userSolutionType = (this.solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === WebUser.typeGuidId);
      result.push({
        label: this.translateService.instant('Remove') + ' ' + this.translateService.instant('User'),
        icon: 'fas fa-trash',
        command: () => {
          this.removeItem(treeNode);
        },
        disabled: !userSolutionType?.canDelete,
      });
    }

    if (!result.length) {
      result.push({
        icon: 'fas fa-ban',
        label: this.translateService.instant('No options available for this node'),
        disabled: true,
      });
    }

    return result;
  }

  private fetchDisplayInTreesForExpandedSites() {
    let expandedSiteGuidIds = (TreeUtils.getExpandedSiteGuidIds(this.treeNodes) || []);
    if (!expandedSiteGuidIds.length) return;

    this.emitBusy(true);

    const webLoadMap = new WebLoadMap();
    webLoadMap.addCenterTypeRelation(WebEnvironmentUser.typeGuidId, 'user');

    for (const siteGuidId of expandedSiteGuidIds) {
      this.genericService.list(WebEnvironmentUser.typeGuidId, webLoadMap, null, false, siteGuidId, 1)
      .subscribe((response: WebResponse) => {
        this.emitBusy(false);

        const treeNode = this.flatTreeNodes.find(x => x.data.guidId === siteGuidId);
        if (!treeNode) return;

        for (let webObject of response.getWebObjects()) {
          const newTreeNode = this.makeTreeNodeFromDesignTreeNode(webObject);
          treeNode.children.push(newTreeNode);
        }
        this.refreshTree();
      }, (error: any) => {
        this.emitBusy(false);
      });
      // this.structuralAdminService.list(
      //   siteGuidId !== this.session.siteGuidId ? siteGuidId : undefined,
      //   this.adminMode || this.displayInTreeUserUsages ? 2 : 0,
      //   this.displayInTreeUserUsages.reduce((previousValue: UserUsage, currentValue: UserUsage) => {
      //     previousValue |= currentValue;
      //     return previousValue;
      //   }, UserUsage.NoUsers)
      // )
      // .subscribe((response: WebDesignTree) => {
      //   if (response?.sites) {
      //     this.updateTreeWithDesignTreeRecursively(this.treeNodes, response.sites, null);
      //     this.refreshTree();
      //   }

      //   this.emitBusy(false);
      // }, (error: any) => {

      //   this.emitBusy(false);
      // });
    };
  }


  private updateTreeWithDesignTreeRecursively(treeNodes: TreeNode[], designTreeNodes: WebDesignTreeSite[] | WebObject[] | WebDesignTreeCustomObject[], parentNode: TreeNode): TreeNode[] {
    for (const dtn of designTreeNodes || []) {
      if (!parentNode) {
        treeNodes = treeNodes || []
        let existingNode = treeNodes.find(x => x.data?.guidId === ((dtn as WebDesignTreeSite).guidId || (dtn as WebDesignTreeCustomObject)?.customObject?.guidId));
        if (!existingNode) {
          existingNode = this.makeTreeNodeFromDesignTreeNode(dtn);
          treeNodes.push(existingNode);
        }

        this.updateTreeWithDesignTreeRecursively(existingNode.children, (dtn as WebDesignTreeSite).sites, existingNode);
        this.updateTreeWithDesignTreeRecursively(existingNode.children, (dtn as WebDesignTreeSite).objects, existingNode);
        this.updateTreeWithDesignTreeRecursively(existingNode.children, (dtn as WebDesignTreeSite).customObjects, existingNode);
      } else {
        let existingNode = (parentNode.children || []).find(x => x.data?.guidId === ((dtn as WebDesignTreeSite).guidId || (dtn as WebDesignTreeCustomObject)?.customObject?.guidId));
        if (!existingNode) {
          existingNode = this.makeTreeNodeFromDesignTreeNode(dtn);
          parentNode.children.push(existingNode);
        }

        this.updateTreeWithDesignTreeRecursively(parentNode.children, (dtn as WebDesignTreeSite).sites, existingNode);
        this.updateTreeWithDesignTreeRecursively(parentNode.children, (dtn as WebDesignTreeSite).objects, existingNode);
        this.updateTreeWithDesignTreeRecursively(parentNode.children, (dtn as WebDesignTreeSite).customObjects, existingNode);
      }
    }

    return treeNodes;
  }

  private makeTreeNodeFromDesignTreeNode(dtn: WebDesignTreeSite | WebObject | WebDesignTreeCustomObject | WebBaseSite | WebBaseUser): TreeNode {
    let guidId = undefined;
    let label = undefined;
    let parentGuidId = undefined;
    let typeGuidId = undefined;
    let userUsage = UserUsage.NoUsers;
    if (dtn instanceof WebDesignTreeSite) {
      guidId = dtn.guidId;
      label = dtn.name;
      parentGuidId = dtn.parentSiteGuidId;
      typeGuidId = WebSite.typeGuidId;
    } else if (dtn instanceof WebObject) {
      const solutionType = (this.solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === dtn.typeGuidId);
      guidId = dtn.guidId;
      label = solutionType?.getMainMemberLabel(dtn) || dtn.members?.name || dtn.guidId;
      parentGuidId = dtn.siteGuidId;
      typeGuidId = dtn.typeGuidId;
    } else if (dtn instanceof WebDesignTreeCustomObject && dtn.customType === 'Web2BaseTreeUser') {
      guidId = dtn.customObject.guidId;
      label = dtn.customObject.name;
      parentGuidId = undefined;
      typeGuidId = WebUser.typeGuidId;
      userUsage = dtn.customObject.isUsageDevice && dtn.customObject.isUsageWeb ? (UserUsage.Web | UserUsage.Device) : dtn.customObject.isUsageWeb ? UserUsage.Web : dtn.customObject.isUsageDevice ? UserUsage.Device : UserUsage.None;
    } else {
      guidId = (dtn as WebBaseSite).guidId;
      label = (dtn as WebBaseSite).name;
      typeGuidId = typeGuidId;
    }

    const node: TreeNode = {
      data: {
        guidId: guidId,
        parentGuidId: parentGuidId,
        typeGuidId: typeGuidId,
        userUsage: userUsage,
      },
      label: label,
      icon: this.getIconForTreeNode(dtn),
      leaf: false,
      children: []
    };

    return node;
  }

  private getIconForTreeNode(dtn: WebDesignTreeSite | WebObject | WebDesignTreeCustomObject | WebBaseSite | WebBaseUser): string {
    if (!dtn) return '';

    if ((dtn as WebDesignTreeSite).iconUri) return (dtn as WebDesignTreeSite).iconUri;
    if ((dtn as WebBaseSite).address) return 'fas fa-home';

    if ((dtn as WebDesignTreeCustomObject).customObject?.isUsageDevice && (dtn as WebDesignTreeCustomObject).customObject?.isUsageWeb) return 'fas fa-laptop-mobile'
    else if ((dtn as WebDesignTreeCustomObject).customObject?.isUsageDevice && !(dtn as WebDesignTreeCustomObject).customObject?.isUsageWeb) return 'far fa-mobile';
    else if (!(dtn as WebDesignTreeCustomObject).customObject?.isUsageDevice && (dtn as WebDesignTreeCustomObject).customObject?.isUsageWeb) return 'fas fa-laptop'

    return 'far fa-user-circle'
  }

  private refreshTree(selectNode?: TreeNode) {
    this.updateTreeWidth();
    this.cdr.markForCheck();

    if (!this.treeNodes) return;

    TreeUtils.traverseTree(this, this.treeNodes, { sort: true }, (parentNode: TreeNode, currentNode: TreeNode) => {
      // filter tree to only show desired subNode types
      // currentNode.styleClass = currentNode.data.typeGuidId === WebSite.typeGuidId
      // || (this.displayInTreeUserUsages || []).some(uu => (uu & currentNode.data.userUsage) === uu)
      // || currentNode.data.isNew
      // ? undefined : 'hidden';
    });

    if (!selectNode) {
      this.nodeSelect(this.selectedTreeNode || this.treeNodes[0], false);
    } else {
      this.nodeSelect(selectNode, true);
    }

    this.cdr.markForCheck();
  }

  private emitBusy(busy: boolean, longLivedBusy?: boolean) {
    if (longLivedBusy !== undefined) {
      this.longLivedBusy = longLivedBusy;
    }
    this.appService.setBusy(this.longLivedBusy || busy);
  }

  toggleSearch() {
    this.searchMode = !this.searchMode;
    this.searchTypeGuidIds = [WebSite.typeGuidId];
    // if (this.displayInTreeUserUsages?.length) this.searchTypeGuidIds.push(WebUser.typeGuidId);

    this.cdr.markForCheck();
  }

  private updateTreeWidth() {
    if (this.elementRef.nativeElement.parentElement) {
      this.treeWidth = this.elementRef.nativeElement.parentElement.clientWidth;
    }
  }

  private addItem(treeNode: TreeNode, typeGuidId: string, typeLabel: string) {
    if (!treeNode?.data) return;

    this.promptDialog.show({
      title: this.translateService.instant('New') + ' ' + typeLabel,
      label: this.translateService.instant('Name'),
      value: undefined,
    });
    this.promptDialog.onClose = (result: string) => {
      if (result) {
        this.appService.setBusy(true);
        const parentGuidId = treeNode.data.guidId;
        if (typeGuidId === WebSite.typeGuidId) {
          this.structuralAdminService.addSite(parentGuidId, result)
          .subscribe((response: StructuralAdminSiteResponse) => {
            // const newNode = this.makeTreeNodeFromDesignTreeNode(response.web2BaseSite, typeGuidId);
            // newNode.data.isNew = true;
            // treeNode.children.push(newNode);

            // this.refreshTree(newNode);

            this.appService.setBusy(false);
          }, (error: any) => {
            this.appService.setBusy(false);
          });
        } else {
          this.structuralAdminService.addUser(parentGuidId, result)
          .subscribe((response: StructuralAdminUserResponse) => {
            // const newNode = this.makeTreeNodeFromDesignTreeNode(response.web2BaseUser, typeGuidId);
            // newNode.data.isNew = true;
            // treeNode.children.push(newNode);

            // this.refreshTree(newNode);

            this.appService.setBusy(false);
          }, (error: any) => {
            this.appService.setBusy(false);
          });
        }
      }
    };
  }

  private removeItem(treeNode: TreeNode) {
    if (!treeNode?.data) return;

    this.confirmDialog.show({
      title: this.translateService.instant('Delete Item'),
      message: this.translateService.instant('Are you sure you want to delete this item?'),
    });
    this.confirmDialog.onClose = (result: any) => {
      if (result) {
        this.appService.setBusy(true);

        if (treeNode.data.typeGuidId === WebSite.typeGuidId) {
          this.structuralAdminService.removeSite(new WebBaseSite({ guidId: treeNode.data.guidId }))
          .subscribe(() => {
            const index = treeNode.parent.children.findIndex(tn => tn.data.guidId === treeNode.data.guidId);
            if (index >= 0) {
              treeNode.parent.children.splice(index, 1);
            }
            // this.refreshTree(treeNode.parent);

            this.notificationService.success(
              this.translateService.instant('Success'),
              this.translateService.instant('Item deleted successfully.'),
            );

            this.appService.setBusy(false);
          }, (error: any) => {
            this.appService.setBusy(false);
          });
        } else {
          this.structuralAdminService.removeUser(new WebBaseUser({ guidId: treeNode.data.guidId }))
          .subscribe(() => {
            const index = treeNode.parent.children.findIndex(tn => tn.data.guidId === treeNode.data.guidId);
            if (index >= 0) {
              treeNode.parent.children.splice(index, 1);
            }
            // this.refreshTree(treeNode.parent);

            this.notificationService.success(
              this.translateService.instant('Success'),
              this.translateService.instant('Item deleted successfully.'),
            );

            this.appService.setBusy(false);
          }, (error: any) => {
            this.appService.setBusy(false);
          });
        }
      }
    };
  }
}
