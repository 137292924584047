import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';



export class WebEnvironmentSite extends WebObject {

  static typeGuidId = '1c09961c-a4e1-4552-9c28-c9e7ece55796';

  members: {

  };

  relations: {
    site?: WebRelationPointer;
  };

  constructor(item?: Partial<WebEnvironmentSite>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebEnvironmentSite.typeGuidId;
  }

}