import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridUtils, JsonUtils } from 'app/shared/utils';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormFieldComponent } from '../../form-field/form-field.component';

@Component({
  selector: 'lc-grid-celleditor-day-segments',
  templateUrl: 'grid-celleditor-day-segments.component.html',
  styleUrls: ['grid-celleditor-day-segments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellEditorDaySegments implements ICellEditorAngularComp, AfterViewInit {

  @ViewChildren(FormFieldComponent) formFieldComponents: QueryList<FormFieldComponent>;

  params: any;

  daySegmentPercentageChanged: Subject<any>;

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.daySegmentPercentageChanged = new Subject<any>();
    this.daySegmentPercentageChanged
    .pipe(
      debounceTime(500)
    )
    .subscribe((daySegmentPercentage: any) => { this.onCellValueChanged(daySegmentPercentage); });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.formFieldComponents?.forEach((formFieldComponent: FormFieldComponent) => {
        formFieldComponent.disableKeysPropagation();
      });
    }, 100);
  }

  agInit(params): void {
    this.params = params;

    this.params.daySegments = GridUtils.getParamValue(this.params, this.params.daySegments);
    this.params.isPercentage = GridUtils.getParamValue(this.params, this.params.isPercentage);
    this.params.value = this.params.value || {};

    if (
      GridUtils.getParamValue(this.params, this.params.colDef.cellRendererParams?.disabled) &&
      this.params.stopEditing
    ) {
      this.params.stopEditing(true);
    }
  }

  getValue(): any {
    return this.params?.value;
  }

  isPopup(): boolean {
    return true;
  }

  onCellValueChanged(daySegmentValue: any) {
    if (daySegmentValue.oldValue !== daySegmentValue.newValue) {
      this.params.oldValue = JSON.parse(JSON.stringify(this.params.value));
      if (this.params.isPercentage) {
        this.params.newValue = this.params.value[daySegmentValue.guidId] = daySegmentValue.newValue;
      } else {
        this.params.newValue = this.params.value[daySegmentValue.guidId].members.segmentQuantity = daySegmentValue.newValue;
      }

      let fieldSplit = this.params.colDef.field.split('.');
      const fieldKey = fieldSplit[fieldSplit.length - 1];
      const fieldPath = fieldSplit.slice(0, fieldSplit.length - 1).join('.');
      const fieldParent = fieldPath ? JsonUtils.deepFind(this.params.data, fieldPath) : this.params.data;
      fieldParent[fieldKey] = this.params.value;

      if (this.params?.daySegmentValueChanged) {
        this.params.daySegmentValueChanged(this.params, daySegmentValue);
      }
    }

    if (this.params.stopEditing) {
      this.params.stopEditing();
    }

    this.cdr.markForCheck();
  }

  doesWeekDayDaySegmentsTotal100() {
    if (!this.params.isPercentage || !this.formFieldComponents?.length) {
      return true;
    }

    let sum = 0;
    this.formFieldComponents.forEach((ff: FormFieldComponent) => {
      const fieldValue = ff.value != null && ff.value !== '' ? ff.value : (100 / this.params.daySegments.length);
      sum += parseInt((ff.value || 0).toString())
    });
    return sum === 100;
  }

}
