import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';


export enum ButtonType {
  Clear = 'clear',
  Danger = 'danger',
  Fab = 'fab',
  Primary = 'primary',
  PrimaryFull = 'primary-full',
  Secondary = 'secondary',
  SecondaryFlat = 'secondary-flat',
  SecondaryRound = 'secondary-round',
  Success = 'success',
  Warning = 'warning',

}

@Component({
  selector: 'lc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {

  @Input() set type(value: ButtonType) {
    this._type = value;

    this.refresh();
  }
  get type(): ButtonType {
    return this._type;
  }
  private _type: ButtonType;

  @Input() async: boolean;
  @Input() disabled: boolean;
  @Input() icon: string;
  @Input() iconPos: 'bottom' | 'top' | 'left' | 'right' = 'left';
  @Input() isFormSubmit: boolean;
  @Input() label: string;

  @Output() click = new EventEmitter<() => void>();

  asyncDisabled: boolean;
  styleClass: string;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }

  private refresh() {
    switch(this.type) {
      case ButtonType.Danger:
        this.styleClass = 'ui-button-raised ui-button-danger';
        break;
      case ButtonType.Clear:
        this.styleClass = 'ui-button-primary clear';
        break;
      case ButtonType.Fab:
        this.styleClass = 'ui-button-success fab';
        break;
      case ButtonType.Primary:
        this.styleClass = 'ui-button-raised ui-button-primary';
        break;
      case ButtonType.PrimaryFull:
        this.styleClass = 'ui-button-raised ui-button-primary full';
        break;
      case ButtonType.Secondary:
        this.styleClass = 'ui-button-raised ui-button-secondary';
        break;
      case ButtonType.SecondaryFlat:
        this.styleClass = 'ui-button ui-button-secondary';
        break;
      case ButtonType.SecondaryRound:
        this.styleClass = 'ui-button-raised ui-button-secondary round';
        break;
      case ButtonType.Success:
        this.styleClass = 'ui-button-raised ui-button-success';
        break;
      case ButtonType.Warning:
        this.styleClass = 'ui-button-raised ui-button-warning';
        break;
      default:
        this.styleClass =  '';
    }
  }

  trigger(event?: Event) {
    if (event) event.stopPropagation();

    if (!this.disabled && !this.asyncDisabled) {
      if (this.async) {
        this.asyncDisabled = true;
        this.cdr.markForCheck();

        this.click.emit(this.onClickCompleted.bind(this));
      } else {
        this.click.emit(event as any);
      }
    }
  }

  onClickCompleted() {
    setTimeout(() => {
      this.asyncDisabled = false;
      this.cdr.markForCheck();
    }, 10);
  }

}
