import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';



export class WebEnvironmentUser extends WebObject {

  static typeGuidId = 'e261f0af-7d1f-4ef4-8f2a-576e622468dc';

  members: {
    color: string;
  };

  relations: {
    user?: WebRelationPointer;
    communicationGroups?: WebRelationPointer[];
  };

  constructor(item?: Partial<WebEnvironmentUser>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebEnvironmentUser.typeGuidId;
  }

}