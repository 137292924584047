<p-tree *ngIf="!searchMode"
  scrollHeight="flex"
  selectionMode="single"
  [value]="treeNodes"
  [contextMenu]="contextMenu"
  [selection]="selectedTreeNode"
  (onNodeExpand)="nodeExpand($event.node)"
  (onNodeSelect)="nodeSelect($event.node, true, true)"
  (onNodeContextMenuSelect)="nodeSelect($event.node, true)">
  <ng-template let-node pTemplate="default">
    <span [ngClass]="showTypesInTree && node.data.guidId ? 'bold' : ''">
      {{ node.label }}
    </span>

    <span *ngIf="showTypesInTree && node.data.typeDetails"
      [ngClass]="node.data.guidId ? '' : 'gray'">
      {{ node.data.typeDetails }}
    </span>
  </ng-template>
</p-tree>

<p-contextMenu #contextMenu
  [model]="contextMenuItems"
  [hidden]="!contextMenuItems"
  class="contextMenu">
</p-contextMenu>

<div *ngIf="adminMode">
  <lc-confirm-dialog></lc-confirm-dialog>
  <lc-prompt-dialog></lc-prompt-dialog>
</div>
