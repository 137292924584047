<ag-grid-angular *ngIf="modules"
  class="grid {{ theme }}"
  [class.enterprise]="enterprise"
  [class.master-detail]="masterDetail"
  [animateRows]="animateRows"
  [cacheBlockSize]="cacheBlockSize"
  (cellValueChanged)="onCellValueChanged($event)"
  [chartThemeOverrides]="chartThemeOverrides"
  [columnDefs]="columns"
  [detailCellRendererFramework]="detailCellRendererFramework"
  [detailCellRendererParams]="detailCellRendererParams"
  [detailRowHeight]="detailRowHeight"
  [domLayout]="autoHeight ? 'autoHeight' : 'normal'"
  [enableBrowserTooltips]="false"
  [enableCellTextSelection]="!enableRangeSelection"
  [enableCharts]="enableCharts"
  [enableRangeSelection]="enableRangeSelection"
  (firstDataRendered)="firstDataRendered.emit($event)"
  (filterChanged)="filterChanged.emit($event)"
  [getDataPath]="getDataPath"
  [getRowClass]="getRowClass"
  [getRowHeight]="getRowHeight"
  [getRowStyle]="getRowStyle"
  [getServerSideGroupKey]="getServerSideGroupKey"
  (gridSizeChanged)="onGridSizeChanged($event)"
  [groupDefaultExpanded]="groupDefaultExpanded ? groupDefaultExpanded : serverSideDataSource ? undefined : 0"
  [groupMultiAutoColumn]="groupMultiAutoColumn"
  [groupSelectsChildren]="false"
  [gridOptions]="gridOptions"
  (gridReady)="gridReady($event)"
  [id]="gridGuidId"
  [isRowSelectable]="isRowSelectable"
  [isServerSideGroup]="isServerSideGroup"
  [isServerSideGroupOpenByDefault]="isServerSideGroupOpenByDefault"
  [keepDetailRows]="true"
  [masterDetail]="masterDetail"
  (modelUpdated)="modelUpdated.emit($event)"
  [modules]="modules"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  [pinnedBottomRowData]="pinnedBottomRows"
  [pinnedTopRowData]="pinnedTopRows"
  [pivotMode]="pivotMode"
  [processCellForClipboard]="processCellForClipboard"
  [processDataFromClipboard]="processDataFromClipboard"
  [quickFilterText]="gridReadyWithColumns && quickFilter ? quickFilter : undefined"
  [rowClassRules]="rowClassRules"
  (rowClicked)="rowClick($event)"
  [rowData]="rows"
  (rowDoubleClicked)="rowDoubleClicked.emit($event)"
  (rowDragEnd)="rowDragEnd.emit($event)"
  [rowDragManaged]="rowDragManaged"
  [rowModelType]="serverSideDataSource ? 'serverSide' : 'clientSide'"
  [rowSelection]="enableRangeSelection ? 'multiple' : rowSelection"
  [sideBar]="sideBar === true ? defaultSideBar : sideBar"
  (selectionChanged)="selectionChanged.emit($event)"
  [serverSideInfiniteScroll]="serverSideInfiniteScroll"
  serverSideStoreType="partial"
  [singleClickEdit]="singleClickEdit"
  [stopEditingWhenCellsLosesFocus]="true"
  [suppressCopyRowsToClipboard]="true"
  [suppressDragLeaveHidesColumns]="true"
  [suppressMultiRangeSelection]="true"
  [suppressRowClickSelection]="suppressRowClickSelection"
  [tooltipShowDelay]="100"
  [treeData]="treeData">
</ag-grid-angular>

<!-- [embedFullWidthRows]="masterDetail" -->

<div *ngIf="!enterprise"
  class="{{ theme }}">
  <div class="ag-status-bar">
    <div class="ag-status-bar-left">
      <span *ngIf="refreshClick?.observers?.length" class="ag-name-value">
        <a class="link"
          (click)="refreshClick.emit()"
          href="javascript: void(0);">
          <i class="fas fa-sync-alt"></i>
        </a>
      </span>
      &nbsp;&nbsp;
      <span *ngIf="lastUpdateDate" class="ag-name-value">
        <span>{{ 'Last Update' | translate }}</span>:&nbsp;<span class="ag-name-value-value">{{ lastUpdateDate | dfnsFormat: 'HH:mm:ss' }}</span>
      </span>
      &nbsp;&nbsp;
      <span *ngIf="nextUpdateDate" class="ag-name-value">
        <span>{{ 'Next Update' | translate }}</span>:&nbsp;<span class="ag-name-value-value">{{ nextUpdateDate | dfnsFormat: 'HH:mm:ss' }}</span>
      </span>
      &nbsp;&nbsp;
      <span *ngIf="lastUpdateFailed" class="ag-name-value">
        <span class="fas fa-exclamation-circle" style="color: red"></span>
      </span>
    </div>

    <div *ngIf="rowCount != -1"
      class="ag-status-bar-right">
      <div class="ag-name-value">
        <span>{{ 'Rows' | translate }}</span>:&nbsp;<span class="ag-name-value-value">{{ rowCount || 0 }}</span>
      </div>
    </div>
  </div>
</div>