
<p-dialog
  header="New Solution Feature:"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [contentStyle]="{'overflow':'visible'}"
  [(visible)]="isVisible">
  <form #form="ngForm"
    (ngSubmit)="submit(form)"
    novalidate>

    <span class="ui-float-label">
      <input pInputText
        type="text"
        class="input"
        name="name"
        [(ngModel)]="name"
        required />
      <label for="name">Name</label>
    </span>

    <span class="ui-float-label">
      <input pInputText
        type="text"
        class="input"
        name="featureName"
        [(ngModel)]="featureName"
        required />
      <label for="featureName">FeatureName</label>
    </span>

    <span class="ui-float-label">
      <p-dropdown
        name="defaultValue"
        [autoDisplayFirst]="false"
        [options]="defaultValueItems"
        [(ngModel)]="defaultValue"
        required>
      </p-dropdown>
      <label for="defaultValue">Default Value</label>
    </span>


    <!--<span class="ui-float-label">
      <input pInputText
        type="text"
        class="input"
        name="defaultComplexValue"
        [(ngModel)]="defaultComplexValue" />
      <label for="defaultComplexValue">DefaultComplexValue</label>
    </span>-->

  </form>

  <p-footer class="flex-row gap-1 middle">
    <div class="flex-1"></div>
    <p-button styleClass="ui-button-secondary" [label]="'Cancel' | translate" (click)="dismiss()"></p-button>
    <p-button [label]="'OK' | translate" [disabled]="form.invalid" (click)="form.ngSubmit.emit()"></p-button>
  </p-footer>
</p-dialog>
