import { CommunicationType } from "app/center-v2/shared/models/web-center-types/web-communication-part.model";
import { WebBaseAddress } from "./web-base-address.model";

export class WebBaseUserContact {

  preferedCommunicationType: CommunicationType;
  email: string;
  mobilePhoneNumber: string;
  address: WebBaseAddress;

  constructor(item?: Partial<WebBaseUserContact>) {
    Object.assign(this, item);

    this.address = new WebBaseAddress(this.address);
  }

}