<div *ngIf="params?.data"
  class="cell-wrapper"
  [class.cursor-pointer]="!!calendarDay"
  [class]="warningClass"
  style="height: 100%; padding: 0 1px 0 11px;"
  [pTooltip]="getParamValue(params?.tooltip)"
  tooltipPosition="bottom">
  <div class="height-100 width-100"
    (click)="onClick($event)"
    (dblclick)="onDoubleClick($event)">

    <div class="cell-calendar-day">
      {{ calendarDay }}
    </div>

    <i *ngIf="endTimeFromSystem != null && workHours"
      class="cell-calendar-icon fas {{ endTimeFromSystem ? 'fa-gear' : 'fa-user-gear' }}">
    </i>

    <div class="cell-label flex-row gap-1 middle">
      <div *ngIf="workHours || (!absenceHoursFromRequest && !absenceHoursNotFromRequest)"
        [innerHTML]="workHours ? (workHours | number:'1.0-2') : ''">
      </div>

      <div *ngIf="absenceHoursFromRequest"
        class="absence-fromrequest">
        ({{ absenceHoursFromRequest | number:'1.0-2' }})
      </div>

      <div *ngIf="absenceHoursNotFromRequest"
        class="absence-notfromrequest">
        ({{ absenceHoursNotFromRequest | number:'1.0-2' }})
      </div>

      <div class="flex-1"></div>

      <div *ngIf="workHours || scheduleHours"
        class="scheduleHours">
        ({{ ((scheduleHours || 0) | number:'1.0-2') }}-{{ lunchHours || 0 }})
      </div>
    </div>
  </div>
</div>
