import { WebBaseAddress } from './web-base-address.model';

export class WebBaseSite {

  guidId: string;
  name: string;
  shortName: string;

  address: WebBaseAddress;

  constructor(item?: Partial<WebBaseSite>) {
    Object.assign(this, item);

    this.address = new WebBaseAddress(this.address);
  }

}