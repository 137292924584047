import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';

export class WebUser extends WebObject {

  static typeGuidId = '544d4ccc-b086-4588-8756-38ab373105e7';

  members: {
    name: string;
    email: string;
    mobilePhoneNumber: string;
    userGdprAccept: boolean;
    userGdprAcceptDateTime: string;
    userGdprDeclineCount: number;
    lastUserGdprDeclineDateTime: string;
    creatorGdprAccept: boolean;
    creatorGdprAcceptDateTime: string;
    lastGdprRequest: string;
  }

  relations: {
    language?: WebRelationPointer;
  }

  constructor(item?: Partial<WebUser>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebUser.typeGuidId;
  }

}