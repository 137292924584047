import { Injectable } from '@angular/core';
import { TypeEnum } from 'app/shared/models';
import { ApiCenterV2Service } from 'app/shared/services/api/api-center-v2.service';
import { Observable, of } from 'rxjs';
import { delay, expand, map, takeWhile } from 'rxjs/operators';
import { ApiCenterService } from '../../../../shared/services';
import { ViewData, ViewDataResponse } from '../../models';
import { WorkerResponse } from '../../models/worker-response.model';
import { RequestType, SET_TANKS, VIEWTYPE_CORE_GPSDEVICE, VIEWTYPE_CORE_GPSDEVICES, VIEWTYPE_TOPFUEL_FORECASTS, VIEWTYPE_TOPFUEL_PREEMORDERS, VIEWTYPE_TOPFUEL_TANK, VIEWTYPE_TOPFUEL_TASKS, VIEWTYPE_TOPFUEL_TOPFUELORDERS, WorkerRequest, WorkerV2Request } from './worker-request.model';



@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  private readonly URL_SUFFIX = 'api/v0/worker/view';
  private readonly URL_SUFFIX_V2 = 'worker';

  constructor(
    private apiService: ApiCenterService,
    private apiV2Service: ApiCenterV2Service,
  ) { }


  getForecasts(designTreeGuidId: string, filterSiteGuidId: string, tick: number): Observable<ViewDataResponse<any>> {
    let request: WorkerRequest = {
      viewType: VIEWTYPE_TOPFUEL_FORECASTS,
      queryType: '',
      designTreeGuidId: designTreeGuidId,
      designTreeFilterSiteGuidId: filterSiteGuidId,
      tick: tick
    };

    return this.makeRequest(request);
  }

  getGpsDevice(guidId: string, traceFromDate?: Date, traceToDate?: Date): Observable<ViewDataResponse<any>> {
    let request: WorkerRequest = {
      viewType: VIEWTYPE_CORE_GPSDEVICE,
      queryType: '',
      guidId: guidId,
      tick: 0,
      traceFromDateTime: traceFromDate ? traceFromDate.toISOString() : undefined,
      traceToDateTime: traceToDate ? traceToDate.toISOString() : undefined
    };

    return this.makeRequest(request);
  }

  getGpsDevices(designTreeGuidId: string, filterSiteGuidId: string, tick: number): Observable<ViewDataResponse<any>> {
    let request: WorkerRequest = {
      viewType: VIEWTYPE_CORE_GPSDEVICES,
      queryType: '',
      designTreeGuidId: designTreeGuidId,
      designTreeFilterSiteGuidId: filterSiteGuidId,
      tick: tick
    };

    return this.makeRequest(request);
  }

  getTank(guidId: string, traceFromDate?: Date, traceToDate?: Date): Observable<ViewDataResponse<any>> {
    let request: WorkerRequest = {
      viewType: VIEWTYPE_TOPFUEL_TANK,
      queryType: '',
      guidId: guidId,
      tick: 0,
      traceFromDateTime: traceFromDate ? traceFromDate.toISOString() : undefined,
      traceToDateTime: traceToDate ? traceToDate.toISOString() : undefined
    };

    return this.makeRequest(request);
  }

  getTanks(designTreeGuidId: string, filterSiteGuidId: string, tick: number): Observable<ViewDataResponse<any>> {
    const request: WorkerV2Request = {
      requestType: RequestType.Set,
      setGuidId: SET_TANKS,
      siteGuidId: filterSiteGuidId,
      withPermissions: true,
      accessPermissionGuidId: TypeEnum.Tank,
      accessPermissionPath: 'View',
      permissionGuidIds: [ TypeEnum.Tank, TypeEnum.PreemOrder ],
      fromTick: tick
    };

    return this.makeV2Request(request);
  }

  getPreemOrders(designTreeGuidId: string, filterSiteGuidId: string, tick: number): Observable<ViewDataResponse<any>> {
    let request: WorkerRequest = {
      viewType: VIEWTYPE_TOPFUEL_PREEMORDERS,
      queryType: '',
      designTreeGuidId: designTreeGuidId,
      designTreeFilterSiteGuidId: filterSiteGuidId,
      tick: tick
    };

    return this.makeRequest(request);
  }

  getTopFuelOrders(designTreeGuidId: string, filterSiteGuidId: string, tick: number): Observable<ViewDataResponse<any>> {
    let request: WorkerRequest = {
      viewType: VIEWTYPE_TOPFUEL_TOPFUELORDERS,
      queryType: '',
      designTreeGuidId: designTreeGuidId,
      designTreeFilterSiteGuidId: filterSiteGuidId,
      tick: tick
    };

    return this.makeRequest(request);
  }

  getTasks(designTreeGuidId: string, filterSiteGuidId: string, tick: number): Observable<ViewDataResponse<any>> {
    let request: WorkerRequest = {
      viewType: VIEWTYPE_TOPFUEL_TASKS,
      queryType: '',
      designTreeGuidId: designTreeGuidId,
      designTreeFilterSiteGuidId: filterSiteGuidId,
      tick: tick
    };

    return this.makeRequest(request);
  }

  getSolutionEventSystemEvents(
    designTreeGuidId: string,
    solutionEventSystemGuidId: string,
    fromTime: Date,
    toTime: Date,
    tick: number,
  ): Observable<ViewDataResponse<any>> {
    const request: WorkerV2Request = {
      requestType: RequestType.SolutionEvents,
      solutionEventsFilterProperties: {
        solutionEventSystemGuidId: solutionEventSystemGuidId,
        indesigntreeguidid: designTreeGuidId,
        fromTime: fromTime ? fromTime.toISOString() : undefined,
        toTime: toTime ? toTime.toISOString() : undefined,
      },
      fromTick: tick,
    };

    return this.makeV2Request(request);
  }

  private makeRequest(request: WorkerRequest): Observable<ViewDataResponse<any>> {
    let workerGuidId: string;
    return this.apiService.post<WorkerResponse | ViewDataResponse<any>>(
      this.URL_SUFFIX,
      request
    )
    .pipe(
      expand((response: WorkerResponse | ViewDataResponse<any>) => {
        if ((response as WorkerResponse).workerGuidId) {
          workerGuidId = (response as WorkerResponse).workerGuidId;
          return this.apiService.get<ViewDataResponse<any>>(
            `${this.URL_SUFFIX}/${workerGuidId}/${request.tick}`
          );
        } else if (!(response as ViewDataResponse<any>).workerFinal) {
          return this.apiService.get<ViewDataResponse<any>>(
            `${this.URL_SUFFIX}/${workerGuidId}/${(<ViewDataResponse<any>>response).workerTick}`
          );
        } else {
          return of(response);
        }
      }, 1),
      takeWhile((response: any) => {
        return response.workerGuidId || !response.workerFinal;
      }),
      map((vdr: ViewDataResponse<any>) => {
        if (vdr.webViewDataSources) {
          vdr.webViewDataSources = (vdr.webViewDataSources || []).map(x => new ViewData(x));

          vdr.webViewDataSources.forEach((data: ViewData<any>) => {
            // relation properties sometimes are sent camelCase other times PascalCase
            // so....standardize them to camelCase.
            data.relation = Object.keys(data.relation || {}).reduce(function(newObject, currentKey) {
              const newKey = currentKey.substring(0, 1).toLowerCase() + currentKey.substring(1);
              newObject[newKey] = data.relation[currentKey];
              if (newKey !== currentKey) {
                delete newObject[currentKey];
              }
              return newObject;
            }, {});
          });
        }

        return new ViewDataResponse(vdr);
      }),
      delay(10)
    );
  }

  private makeV2Request(request: WorkerV2Request): Observable<ViewDataResponse<any>> {
    let workerGuidId: string;
    return this.apiV2Service.post<WorkerResponse | ViewDataResponse<any>>(
      this.URL_SUFFIX_V2,
      request
    )
    .pipe(
      expand((response: WorkerResponse | ViewDataResponse<any>) => {
        if ((response as WorkerResponse).workerGuidId) {
          workerGuidId = (response as WorkerResponse).workerGuidId;
          return this.apiV2Service.get<ViewDataResponse<any>>(
            `${this.URL_SUFFIX_V2}/${workerGuidId}/${request.fromTick}`
          ).pipe(
            delay(10)
          );
        } else if (!(response as ViewDataResponse<any>).workerFinal) {
          return this.apiV2Service.get<ViewDataResponse<any>>(
            `${this.URL_SUFFIX_V2}/${workerGuidId}/${(response as ViewDataResponse<any>).workerTick}`
          ).pipe(
            delay(10)
          );
        } else {
          return of(response);
        }
      }, 1),
      takeWhile((response: any) => {
        return response.workerGuidId || !response.workerFinal;
      }),
      map((vdr: ViewDataResponse<any>) => {
        return new ViewDataResponse(vdr);
      }),
    );
  }

}
