import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { BrowserUtils } from 'app/shared/utils';
import { Chips } from 'primeng/chips';


@Component({
  selector: 'lc-form-field-emails',
  templateUrl: 'form-field-emails.component.html',
  styleUrls: ['./form-field-emails.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldEmailsComponent),
    multi: true
  }],
})
export class FormFieldEmailsComponent implements ControlValueAccessor, AfterViewInit {

  readonly id = Date.now();
  readonly noValueHtml = `<span class="no-value">-</span>`;

  @ViewChild(Chips, { static: false }) chipsComponent: Chips;

  @Input() appendTo: any;
  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() max: number;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() separator: string;

  onChange: (x: any) => {};
  onTouched: () => {};

  invalid: boolean;
  isFocused = false;
  isTouched: boolean;
  value: string;
  values: string[];

  constructor(
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {

  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;

      this.values = this.getChipsFromValue(this.value);

      this.cdr.markForCheck();

      setTimeout(() => {
        this.cdr.markForCheck();
      }, 100);
    }
  }

   // From ControlValueAccessor interface
   registerOnChange(fn: any) {
    this.onChange = (value: any) => {
      this.invalid = this.required && (value == null || !value?.length);
      return fn(value);
    };
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = () => {
      this.isTouched = true;
      return fn();
    };
  }

  ngAfterViewInit() {
    // HACK: Override primeng chips addItem method
    if (!this.chipsComponent) return;

    this.chipsComponent.addItem = function(event: Event, item: string, preventDefault: boolean) {
      this.value = this.value || [];
      item = (item || '').trim();
      if (item?.length > 0) {
        if (this.allowDuplicate || this.value.indexOf(item) === -1) {
          if (/^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/.test(item)) {
            this.value = [...this.value, item];
            this.onModelChange(this.value);
            this.onAdd.emit({
                originalEvent: event,
                value: item,
            });
          }
        }
      }
      this.updateFilledState();
      this.updateMaxedOut();
      this.inputViewChild.nativeElement.value = '';

      if (preventDefault) {
        event.preventDefault();
      }
    }

    this.chipsComponent.onKeydown = function(event: KeyboardEvent): void {
      switch(event.which) {
        case 8: // backspace
          if (this.inputViewChild.nativeElement.value.length === 0 && this.value?.length > 0) {
            this.value = [...this.value];
            let removedItem = this.value.pop();
            this.onModelChange(this.value);
            this.onRemove.emit({
                originalEvent: event,
                value: removedItem
            });
            this.updateFilledState();
          }
          break;
        case 13: // enter
          this.addItem(event, this.inputViewChild.nativeElement.value, true);
          break;
        case 9: // tab
          if (this.addOnTab && this.inputViewChild.nativeElement.value !== '') {
            this.addItem(event, this.inputViewChild.nativeElement.value, true);
          }
          break;
        default:
          if (this.max && this.value && this.max === this.value.length) {
            event.preventDefault();
          } else if (this.separator) {
            if (this.separator === event.key) {
              this.addItem(event, this.inputViewChild.nativeElement.value, true);
            }
          }
          break;
      }
    }
  }

  getChipsFromValue(value: string) {
    return value?.length ? value.split(this.separator) : [];
  }

  setValueFromChips(values: string[]) {
    this.value = (values || []).join(';');

    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  copyToClipboard(ev: Event, value: string) {
    ev.stopPropagation();

    BrowserUtils.copyToClipboard(value || '');

    this.notificationService.info(
      this.translateService.instant('Info'),
      this.translateService.instant('Email copied to the clipboard.'),
    );
  }

}
