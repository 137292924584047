<lc-form-field *ngIf="params"
  class="cell-field"
  [style.min-width]="params.type !== 'checkbox' ? (params.eGridCell.clientWidth || 0) + 'px' : ''"
  [appendTo]="params.appendTo"
  [disabled]="params.disabled"
  [editMode]="true"
  (keydown.tab)="$event.stopPropagation()"
  [maxNumber]="params.maxNumber"
  [minNumber]="params.minNumber"
  [(ngModel)]="params.value"
  (ngModelChange)="cellValueChanged.next()"
  [options]="params.options"
  [pattern]="params.pattern"
  [placeholder]="params.placeholder"
  [readOnly]="params.readOnly"
  [type]="params.type || 'text'">
</lc-form-field>