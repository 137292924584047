import { WebBaseUserContact } from './web-base-user-contact.model';

export class WebBaseUser {

  guidId: string;
  name: string;
  shortName: string;

  contact: WebBaseUserContact;
  isUsageDevice: boolean;
  isUsageWeb: boolean;
  usageDevice?: {
    username: string;
    password?: string;
  };
  usageWeb?: {
    allowedActiveSessionCount: number;
    groups?: any;
    workspaces: any[];
  };

  constructor(item?: Partial<WebBaseUser>) {
    Object.assign(this, item);

    this.contact = new WebBaseUserContact(this.contact);
  }

}