<div class="ag-drag-handle ag-row-drag flex-row middle gap-1"
  mwlDraggable
  dragActiveClass="ag-theme-balham drag-active"
  [dropData]="getDropData()"
  [ghostElementAppendTo]="bodyEl"
  (mousedown)="refresh(params)"
  [pTooltip]="'Drag me to the technician calendar' | translate">
  <div class="ag-icon ag-icon-grip">
  </div>
  <div>
    {{ dragLabel || '&nbsp;' }}
  </div>
</div>
