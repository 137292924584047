import { AgRendererComponent } from '@ag-grid-community/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebWorkScheduleDefinedDay } from 'app/center-v2/shared/models/web-center-types/web-work-schedule-defined-day.model';
import { WorkScheduleAbsence, WorkScheduleDay } from 'app/center-v2/shared/models/web-center-types/web-work-schedule.model';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { CaseUtils } from 'app/shared/utils';
import { DateTimeIndexUtils } from 'app/shared/utils/date-time-index.utils';
import { BaseGridCellRenderer } from '../base/base-grid-cellrenderer.component';

@Component({
  selector: 'lc-grid-cellrenderer-workscheduleday',
  templateUrl: 'grid-cellrenderer-workscheduleday.component.html',
  styleUrls: ['grid-cellrenderer-workscheduleday.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellRendererWorkScheduleDay extends BaseGridCellRenderer implements AgRendererComponent {

  params: any;

  absenceHoursFromRequest: number;
  absenceHoursNotFromRequest: number;
  calendarDay: number;
  endTimeFromSystem: boolean;
  lunchHours: number;
  scheduleHours: number;
  warningClass: string;
  workHours: number;

  constructor(
    cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    super(cdr);
  }

  agInit(params): void {
    this.params = params;

    const currentDayIndex = DateTimeIndexUtils.encodeDateToDayIndex(new Date());
    let isPastDate: boolean;
    let workScheduleDay: WorkScheduleDay;
    if (this.params.value instanceof WebWorkScheduleDefinedDay) {
      const webWorkScheduleDefinedDay: WebWorkScheduleDefinedDay = this.params.value;
      workScheduleDay = new WorkScheduleDay(CaseUtils.toCamel(JSON.parse(webWorkScheduleDefinedDay.members.workScheduleDay)));
      this.calendarDay = DateTimeIndexUtils.decodeDayIndexToDate(webWorkScheduleDefinedDay.members.dayIndex).getDate();
      this.endTimeFromSystem = !!webWorkScheduleDefinedDay.members.endTimeFromSystem;
      isPastDate = webWorkScheduleDefinedDay.members.dayIndex < currentDayIndex;
    } else if (this.params.value instanceof WorkScheduleDay) {
      workScheduleDay = this.params.value;
      this.calendarDay = DateTimeIndexUtils.decodeDayIndexToDate((workScheduleDay as any).$dayIndex).getDate();
      // this.endTimeFromSystem = workScheduleDay.endTimeFromSystem;
      isPastDate = (workScheduleDay as any).$dayIndex < currentDayIndex;
    }
    if (!workScheduleDay) return;

    this.absenceHoursFromRequest = 0;
    this.absenceHoursNotFromRequest = 0;
    for (const absence of workScheduleDay.absences || []) {
      if (absence.fromRequest) {
        this.absenceHoursFromRequest += (absence.endTimeIndex - absence.startTimeIndex) / (60 * 60);
      } else {
        this.absenceHoursNotFromRequest += (absence.endTimeIndex - absence.startTimeIndex) / (60 * 60);
      }
    }

    if (workScheduleDay.scheduleDayEndTimeIndex && workScheduleDay.scheduleDayStartTimeIndex) {
      this.scheduleHours = (workScheduleDay.scheduleDayEndTimeIndex - workScheduleDay.scheduleDayStartTimeIndex) / (60 * 60);
      this.lunchHours = workScheduleDay.lunchHours;
    }

    this.workHours = 0;
    if (workScheduleDay.dayEndTimeIndex && workScheduleDay.dayStartTimeIndex) {
      this.workHours += (workScheduleDay.dayEndTimeIndex - workScheduleDay.dayStartTimeIndex) / (60 * 60);
      this.workHours = this.workHours - (this.lunchHours || 0);
    }
    for (const item of workScheduleDay.items || []) {
      this.workHours += (item.endTimeIndex - item.startTimeIndex) / (60 * 60);
    }

    this.cdr.markForCheck();
    if (!isPastDate || !this.scheduleHours) return;

    this.warningClass = !this.workHours
    ? 'no-work'
    : Math.abs(1 - ((this.workHours || 0) / this.scheduleHours)) > .15
    ? (this.scheduleHours > this.workHours ? 'work-incomplete' : 'work-complete')
    : '';

    this.cdr.markForCheck();
  }

  refresh(params: any): boolean {
    this.cdr.markForCheck();
    return false;
  }

  onClick(ev: Event) {
    if (!this.params?.click) return;

    ev.stopPropagation();

    this.params?.click && !this.params?.disabled ? this.params.click(this.params, ev) : null;
  }

  onDoubleClick(ev: Event) {
    if (!this.params?.doubleClick) return;

    ev.stopPropagation();

    this.params?.doubleClick && !this.params?.disabled ? this.params.doubleClick(this.params, ev) : null;
  }

}
