<div class="form-field"
  (click)="onTouched()">
  <label *ngIf="label"
    class="label"
    [innerHTML]="label">
  </label>

  <div *ngIf="!editMode"
    class="value">
    <div class="flex-row middle">
      <div *ngIf="iconLeft" [ngClass]="iconLeft"></div>

      <div [innerHTML]="readOnlyValue != null ? readOnlyValue : noValueHtml">
      </div>

      <div *ngIf="iconRight" [ngClass]="iconRight"></div>
    </div>
  </div>

  <div *ngIf="editMode">
    <p-multiSelect
      [appendTo]="appendTo"
      [disabled]="disabled"
      [(ngModel)]="multiSelectValue"
      (ngModelChange)="setValueFromMultiSelectFlagsValue($event)"
      [options]="options"
      [optionLabel]="optionLabel"
      panelStyleClass="ui-multiselect-no-header"
      [filter]="false"
      [showToggleAll]="false"
      defaultLabel="None"
      required>
    </p-multiSelect>
  </div>
</div>
